var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "editForm",
          staticClass: "table-class demo-form-inline",
          attrs: { model: _vm.editForm, rules: _vm.rules },
        },
        [
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: false,
                  expression: "false",
                },
              ],
              attrs: { gutter: 80 },
            },
            [
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "工单编号", prop: "workOrderNo" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: !_vm.isAdd, placeholder: "" },
                        model: {
                          value: _vm.editForm.workOrderNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "workOrderNo", $$v)
                          },
                          expression: "editForm.workOrderNo",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 80 } },
            [
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "报销总金额", prop: "amount" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: true, placeholder: "" },
                        model: {
                          value: _vm.editForm.amount,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "amount", $$v)
                          },
                          expression: "editForm.amount",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 80 } },
            [
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 } },
                [
                  _c(
                    "el-form-item",
                    [
                      _c("costadd", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isShowAmountAnnex,
                            expression: "isShowAmountAnnex",
                          },
                        ],
                        ref: "photobx",
                        attrs: {
                          "is-show-file-name": false,
                          type: "50",
                          "is-edit": false,
                          row: _vm.object,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 80 } },
            [
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "快递公司", prop: "courierCompany" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: true, placeholder: "" },
                        model: {
                          value: _vm.editForm.courierCompany,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "courierCompany", $$v)
                          },
                          expression: "editForm.courierCompany",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 80 } },
            [
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "快递单号", prop: "courierNumber" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: true, placeholder: "" },
                        model: {
                          value: _vm.editForm.courierNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "courierNumber", $$v)
                          },
                          expression: "editForm.courierNumber",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 80 } },
            [
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 } },
                [
                  _c(
                    "el-form-item",
                    [
                      _c("costadd", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isShowNumberAnnex,
                            expression: "isShowNumberAnnex",
                          },
                        ],
                        ref: "photodh",
                        attrs: {
                          "is-show-file-name": false,
                          "is-edit": false,
                          type: "50",
                          row: _vm.object,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }