<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    :close-on-click-modal="false"
    width="500px"
    @close="close"
  >
    <div style="margin-bottom: 20px">该工单是否确认放弃？</div>
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <el-form-item label="内审人员" prop="title">
        <el-select
          v-model="form.title"
          placeholder="请选择内审人员"
          clearable
          filterable
        >
          <el-option
            v-for="item in neishenOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="放弃原因" prop="author">
        <el-select
          v-model="form.title"
          placeholder="请选择放弃原因"
          clearable
          filterable
        >
          <el-option
            v-for="item in quitOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="小记" prop="remark">
        <el-input
          v-model="form.remark"
          type="textarea"
          placeholder="请输入小记"
          autocomplete="off"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" :loading="quitLoading" @click="save">
        确 定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
  export default {
    name: "Edit",
    data() {
      return {
        quitLoading: false,
        form: {
          title: "",
          author: "",
          remark: "",
        },
        rules: {
          remark: [
            { required: true, message: "请输入小记", trigger: "blur" },
            {
              max: 500,
              message: "小记不能超过500个汉字",
              trigger: "blur",
            },
          ],
        },
        title: "",
        dialogFormVisible: false,
        neishenOptions: [
          {
            value: "选项1",
            label: "bunny",
          },
          {
            value: "选项2",
            label: "kenshin",
          },
        ],
        quitOptions: [
          {
            value: "选项1",
            label: "不告诉你",
          },
          {
            value: "选项2",
            label: "就不告诉你",
          },
        ],
      };
    },
    created() {},
    methods: {
      showReject(row) {
        this.title = "质疑信息";
        this.form = Object.assign({}, row);
        this.dialogFormVisible = true;
      },
      close() {
        this.$refs["form"].resetFields();
        this.form = this.$options.data().form;
        this.dialogFormVisible = false;
        // this.$emit("fetchData");
      },
      save() {
        this.$refs["form"].validate(async (valid) => {
          if (valid) {
            this.quitLoading = true;
            const { msg } = await doEdit(this.form);
            this.$baseMessage(msg, "success");
            this.$refs["form"].resetFields();
            this.dialogFormVisible = false;
            this.$emit("fetchData");
            this.form = this.$options.data().form;
            this.quitLoading = false;
          } else {
            return false;
          }
        });
      },
    },
  };
</script>
<style scoped>
  .el-select,
  .el-cascader {
    width: 100%;
  }
</style>
