var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "editForm",
          staticClass: "table-class demo-form-inline",
          attrs: { model: _vm.editForm, rules: _vm.rules },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 80 } },
            [
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "成本总金额", prop: "costAmount" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: true, placeholder: "" },
                        model: {
                          value: _vm.editForm.costAmount,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "costAmount", $$v)
                          },
                          expression: "editForm.costAmount",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 80 } },
            [
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 } },
                [
                  _c(
                    "el-form-item",
                    [
                      _c("costadd", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isShowAttachment,
                            expression: "isShowAttachment",
                          },
                        ],
                        ref: "photo",
                        attrs: {
                          "is-show-file-name": false,
                          "is-show-down-load": false,
                          type: "50",
                          row: _vm.object,
                          "is-edit": false,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }