var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c("order-process", { attrs: { param: _vm.param } }),
          _c("div", { staticClass: "title" }, [_vm._v("基础信息")]),
          _c(
            "el-table",
            { attrs: { data: _vm.tableData, stripe: "", border: "" } },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "workOrderNo",
                  label: "工单编号",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "projectNameNo",
                  label: "Project Name",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "projectName", label: "项目", align: "center" },
              }),
            ],
            1
          ),
          _c(
            "el-table",
            { attrs: { data: _vm.tableData, stripe: "", border: "" } },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "targetSubject",
                  label: "目标主体",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "address",
                  label: "国家/省份/城市/地域（县级市）",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "targetAddr1",
                  label: "目标地址1",
                  align: "center",
                },
              }),
            ],
            1
          ),
          _c(
            "el-table",
            { attrs: { data: _vm.tableData, stripe: "", border: "" } },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "targetAddr2",
                  label: "目标地址2",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "targetContact",
                  label: "目标联系人",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(scope.row.targetContact || "-") + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "contactNumber",
                  label: "联系电话",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(scope.row.contactNumber || "-") + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "el-table",
            { attrs: { data: _vm.tableData, stripe: "", border: "" } },
            [
              _c("el-table-column", {
                attrs: { prop: "duty", label: "职务", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(" " + _vm._s(scope.row.duty || "-") + " ")]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "orderTime",
                  label: "下单时间",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(" " + _vm._s(scope.row.orderTime || "-") + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "innerDeadline",
                  label: "内部截止日期",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(scope.row.innerDeadline || "-") + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "el-table",
            { attrs: { data: _vm.tableData, stripe: "", border: "" } },
            [
              _c("el-table-column", {
                attrs: { prop: "remark1", label: "备注1", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(" " + _vm._s(scope.row.remark1 || "-") + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "remark2", label: "备注2", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(" " + _vm._s(scope.row.remark2 || "-") + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "remark3", label: "备注3", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(" " + _vm._s(scope.row.remark3 || "-") + " "),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "el-table",
            { attrs: { data: _vm.tableData, stripe: "", border: "" } },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "clientFullName",
                  label: "委托方全称",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(scope.row.clientFullName || "-") + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "clientAbbr",
                  label: "委托方简称",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(" " + _vm._s(scope.row.clientAbbr || "-") + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "reportTimeLimit",
                  label: "报告时限",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(scope.row.reportTimeLimit || "-") + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("div", { staticClass: "title" }, [_vm._v("附加信息")]),
          _c(
            "el-table",
            { attrs: { data: _vm.tableData, stripe: "", border: "" } },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "expectVisitDate",
                  label: "预计走访日期",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(scope.row.expectVisitDate || "-") + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "expectServeyDay",
                  label: "预计所需调查天数",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(scope.row.expectServeyDay || "-") + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "needUser", label: "所需人手", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(" " + _vm._s(scope.row.needUser || "-") + " "),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "el-table",
            { attrs: { data: _vm.tableData, stripe: "", border: "" } },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "expectHour",
                  label: "预计需时（小时）",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(" " + _vm._s(scope.row.expectHour || "-") + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "registerAddress",
                  label: "注册地址",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(scope.row.registerAddress || "-") + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "targetSubjectRegStatus",
                  label: "目标主体注册状态",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(scope.row.targetSubjectRegStatus || "-") +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "el-table",
            { attrs: { data: _vm.tableData, stripe: "", border: "" } },
            [
              _c("el-table-column", {
                attrs: { prop: "visitFee", label: "走访收费", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(" " + _vm._s(scope.row.visitFee || "-") + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "urgentVisitFee",
                  label: "加急走访收费",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(scope.row.urgentVisitFee || "-") + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "againVisitFee",
                  label: "再次走访收费",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(scope.row.againVisitFee || "-") + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "el-table",
            { attrs: { data: _vm.tableData, stripe: "", border: "" } },
            [
              _c("el-table-column", {
                attrs: { prop: "feeBasis", label: "收费依据", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(" " + _vm._s(scope.row.feeBasis || "-") + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "targetOrganization1",
                  label: "目标机构1",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(scope.row.targetOrganization1 || "-") +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "targetOrganization2",
                  label: "目标机构2",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(scope.row.targetOrganization2 || "-") +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "el-table",
            { attrs: { data: _vm.tableData, stripe: "", border: "" } },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "targetResult",
                  label: "目标地址预核结果",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(scope.row.targetResult || "-") + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "infeasibleReason",
                  label: "不可行原因/待确认项目",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(scope.row.infeasibleReason || "-") + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "mailHeader",
                  label: "邮件抬头",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(" " + _vm._s(scope.row.mailHeader || "-") + " "),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("div", { staticClass: "title" }, [_vm._v("特别信息")]),
          _c(
            "el-table",
            { attrs: { data: _vm.tableData, stripe: "", border: "" } },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "plSpecialMsg",
                  label: "PL特别留言",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(scope.row.plSpecialMsg || "-") + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "bookSpecialMsg",
                  label: "预约特别留言",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(scope.row.bookSpecialMsg || "-") + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "monitorSpecialMsg",
                  label: "督导特别留言",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(scope.row.monitorSpecialMsg || "-") + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.annexGroup.length !== 0,
                  expression: "annexGroup.length !== 0",
                },
              ],
              staticClass: "title",
            },
            [_vm._v("附件")]
          ),
          _c(
            "el-row",
            { staticStyle: { "text-align": "center", "margin-top": "20px" } },
            _vm._l(_vm.annexGroup, function (item, index) {
              return _c(
                "div",
                { key: index },
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 6 } },
                    [
                      _c(
                        "a",
                        {
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.handleDownload({
                                name: item.fileName,
                                path: item.path,
                              })
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("@/icon/annex.svg"),
                              alt: "",
                              width: "80",
                              height: "80",
                            },
                          }),
                          _c("p", [_vm._v(_vm._s(item.fileName))]),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              )
            }),
            0
          ),
          _c("div", { staticClass: "title" }, [_vm._v("操作信息")]),
          _c(
            "el-table",
            { attrs: { data: _vm.operationInfo, stripe: "", border: "" } },
            [
              _c("el-table-column", {
                attrs: { prop: "operator", label: "操作者", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(" " + _vm._s(scope.row.operator || "-") + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "role", label: "角色", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(" " + _vm._s(scope.row.role || "-") + " ")]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "operAction",
                  label: "操作状态",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(" " + _vm._s(scope.row.operAction || "-") + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: "操作时间",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(" " + _vm._s(scope.row.createTime || "-") + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "remark", label: "备注", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(" " + _vm._s(scope.row.remark || "-") + " "),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        [
          _c("h3", [_vm._v("绩效详情")]),
          _c("performance-com", {
            ref: "supervision",
            attrs: {
              "no-action": true,
              subject: "督导",
              "data-list": _vm.supervisionDataList,
            },
          }),
          _c("performance-com", {
            ref: "investigate",
            attrs: {
              "no-action": true,
              subject: "调查",
              "data-list": _vm.investigationDataList,
            },
          }),
          _c("performance-com", {
            ref: "internalAudit",
            attrs: {
              "no-action": true,
              subject: "内审",
              "data-list": _vm.internalAuditDataList,
            },
          }),
        ],
        1
      ),
      _c(
        "el-card",
        [
          _c("h3", [_vm._v("统计录入")]),
          _c("statisticalView", {
            ref: "statisticalView",
            on: { refreshData: _vm.refreshData },
          }),
        ],
        1
      ),
      _c(
        "el-card",
        [
          _c("h3", [_vm._v("成本录入")]),
          _c("costentryView", {
            ref: "costentryView",
            on: { refreshData: _vm.refreshData },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }