<template>
  <div class="app-main-container">
    <h3>{{ subject }}绩效</h3>
    <el-form
      ref="performanceForm"
      :model="performanceForm"
      :rules="rules"
      :inline="true"
      label-width="100px"
    >
      <el-form-item :label="`${subject}总绩效`" prop="totalPerformance">
        <el-input
          v-model="performanceForm.totalPerformance"
          :disabled="noAction"
          :clearable="true"
          @keyup.native="
            performanceForm.totalPerformance = performanceForm.totalPerformance.replace(
              performanceForm.totalPerformance,
              handleRestricted(performanceForm.totalPerformance)
            )
          "
          @change="handleInputChange(performanceForm.totalPerformance)"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button v-if="!noAction" @click="handleAdd">新增</el-button>
      </el-form-item>
    </el-form>

    <el-table :data="dataList" border>
      <el-table-column type="index" label="序号" align="center" width="50" />
      <el-table-column label="姓名" align="center">
        <template v-slot="scope">
          <el-select
            v-if="!noAction"
            v-model="scope.row.username"
            :placeholder="`请选择${subject}人员`"
            :clearable="true"
            filterable
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-input
            v-else
            v-model="scope.row.usernameValue"
            :readonly="noAction"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="绩效" align="center">
        <template v-slot="scope">
          <el-input
            v-model="scope.row.merits"
            placeholder="请输入绩效"
            :clearable="true"
            :readonly="noAction"
            @keyup.native="
              scope.row.merits = scope.row.merits.replace(
                scope.row.merits,
                handleRestricted(scope.row.merits)
              )
            "
            @change="handleInputChange(scope.row.merits)"
          />
        </template>
      </el-table-column>
      <el-table-column v-if="!noAction" label="操作" align="center" width="120">
        <template v-slot="scope">
          <el-button type="danger" @click="handleDelete(scope.row)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
  import { getUsersListByRole } from "@/api/workplace/tools";
  import { uuid, getRoleKey } from "@/utils";
  import { mapGetters } from "vuex";

  export default {
    name: "PerformanceCom",
    props: {
      // 主体信息
      subject: {
        type: String,
        default: "",
      },
      // 不可操作标识
      noAction: {
        type: Boolean,
        default: false,
      },
      // 触发来源
      source: {
        type: String,
        default: "",
      },
      // 列表数据
      dataList: {
        type: Array,
        default() {
          return [];
        },
      },
    },
    data() {
      return {
        // 表单数据
        performanceForm: {
          totalPerformance: "",
        },
        // 查询角色参数
        queryRole: {
          body: [],
          header: {
            currentTime: "",
            requestId: "",
            sourceType: "site",
          },
        },
        // 人员信息选项
        options: [],
        // 编辑时删除的绩效项
        delArr: [],
        // 表单校验规则
        rules: {
          totalPerformance: [
            { required: true, message: "请添加绩效总数", trigger: "blur" },
          ],
        },
      };
    },
    computed: {
      // 主体类别
      subjectType: function () {
        switch (this.subject) {
          case "督导":
            return 0;
          case "调查":
            return 1;
          case "内审":
            return 2;
          default:
            return null;
        }
      },
      // 角色参数
      roleKey: function () {
        switch (this.subject) {
          case "督导":
            return "supervisor";
          case "调查":
            return ["full_investigator", "part_investigator"];
          case "内审":
            return "verify_people";
          default:
            return "";
        }
      },
      ...mapGetters({
        userNo: "user/userNo",
        roles: "user/roles",
      }),
    },
    mounted() {
      this.handleInitOptions();
    },
    methods: {
      /** 暴漏组件数据 */
      getData() {
        return {
          total: this.performanceForm.totalPerformance,
          list: [...this.dataList, ...this.delArr],
        };
      },
      /** 校验列表绩效 */
      validateItem() {
        return new Promise((resolve, reject) => {
          this.dataList.forEach((item) => {
            if (!item.username || item.merits === "") {
              reject(`${this.subject} 姓名和绩效不可为空！`);
            }
          });
          this.isDuplicate(this.dataList)
            .then(() => {
              resolve(true);
            })
            .catch((error) => {
              reject(`不可重复添加 ${this.subject} 绩效信息！`);
            });
        });
      },
      /** 获取人员选项 */
      async handleInitOptions() {
        // 区分是否调查人员
        if (Array.isArray(this.roleKey)) {
          for (const item of this.roleKey) {
            this.queryRole.body.push({ roleKey: item });
          }
        } else {
          this.queryRole.body.push({ roleKey: this.roleKey });
        }
        try {
          const { header, body } = await getUsersListByRole(this.queryRole);
          if (header.retCode === "1") {
            this.options = body;
          } else {
            this.$message.error(header.retMessage);
          }
        } catch (error) {
          this.options = [];
        }
      },
      /** 新增项 */
      handleAdd() {
        if (this.dataList.length >= 10) {
          this.$message.warning(`最多仅支持添加10条 ${this.subject} 绩效信息`);
          return;
        }
        this.dataList.push({
          uniqueId: uuid(),
          username: "",
          merits: "",
          type: this.subjectType,
        });
      },
      /** 删除项 */
      async handleDelete(data) {
        // 有该属性seqId为后端返回数据，否则为前端新增数据uniqueId
        if (data.seqId) {
          for (const key in this.dataList) {
            if (this.dataList[key].seqId === data.seqId) {
              this.dataList[key].deleteFlag = 1;
              this.delArr = [...this.delArr, ...this.dataList.splice(key, 1)];
            }
          }
        }
        if (data.uniqueId) {
          for (const idx in this.dataList) {
            if (this.dataList[idx].uniqueId === data.uniqueId) {
              this.dataList.splice(idx, 1);
            }
          }
        }
      },
      /** input输入事件
       * 绩效校验输入正负数，保留2位小数
       */
      handleRestricted(values) {
        return this.plusOrMinus(values.toString());
      },
      /** input框内容改变 */
      handleInputChange(value) {
        // 防止删除为空
        if (!value) {
          value = "0.00";
        }
        // 一些错误金额输入的判断
        if (
          value.toString().indexOf(".") > 0 &&
          Number(value.toString().split(".")[1].length) < 1
        ) {
          value = value.toString().split(".")[0];
        }
        // 一些错误金额输入的判断
        if (!value || value === "-" || value === "-0") {
          value = "0.00";
          return;
        }
        // 此时value的值即是最终有效值
        value = parseFloat(value).toFixed(2);
      },
      /* 校验输入正负数，保留2位小数，接收string类型*/
      plusOrMinus(values) {
        let newValue;
        // 非正负数处理
        if (!/[^0-9.-]/g.test(values)) {
          newValue = values
            .replace(/[^\-\d.]/g, "")
            .replace(/\b(0+){2,}/g, "0")
            .replace(/\-{2,}/g, "-")
            .replace(/^\./g, "")
            .replace(/\.{2,}/g, ".")
            .replace(".", "$#$")
            .replace(/\./g, "")
            .replace("$#$", ".");
          if (
            newValue.toString().indexOf(".") > 0 &&
            Number(newValue.toString().split(".")[1].length) > 2
          ) {
            newValue = parseInt(parseFloat(newValue) * 100) / 100;
          }
          if (newValue.toString().split("-").length - 1 > 1) {
            newValue = parseFloat(newValue) || "";
          }
          if (
            newValue.toString().split("-").length > 1 &&
            newValue.toString().split("-")[0].length > 0
          ) {
            newValue = parseFloat(newValue) || "";
          }
          if (
            newValue.toString().length > 1 &&
            (newValue.toString().charAt(0) === "0" ||
              (newValue.toString().length > 2 &&
                newValue.toString().charAt(0) === "-" &&
                newValue.toString().charAt(1) === "0" &&
                newValue.toString().charAt(2) !== ".")) &&
            newValue.toString().indexOf(".") < 1
          ) {
            newValue = parseFloat(newValue) || "";
          }
          // 判断整数位最多为9位
          if (
            newValue.toString().indexOf(".") > 0 &&
            Number(newValue.toString().split(".")[0].length) > 8
          ) {
            newValue =
              newValue.toString().substring(0, 8) +
              "." +
              newValue.toString().split(".")[1];
          } else if (
            newValue.toString().indexOf(".") < 0 &&
            Number(newValue.toString().split(".")[0].length) > 8
          ) {
            newValue = newValue.toString().substring(0, 8);
          }
        } else if ("." === values) {
          // 只输入小数点处理
          newValue = "";
        } else {
          // 合法输入
          newValue = values.replace(/[^0-9.-]/g, "");
        }
        return newValue;
      },
      /** 计算当前列表是否存在重复 */
      isDuplicate(arr) {
        return new Promise((resolve, reject) => {
          let newArr = [];
          for (const item of arr) {
            if (newArr.includes(item.username)) {
              reject(false);
            } else {
              newArr.push(item.username);
            }
          }
          resolve(true);
        });
      },
    },
  };
</script>

<style></style>
