import request from "@/utils/request";

/** 新增绩效信息 */
export function addPerformanceInfo(data) {
  return request({
    url: "/inputMerits",
    method: "post",
    data,
  });
}

/** 查询单模块绩效信息 */
export function fetchPerformanceInfo(data) {
  return request({
    url: "/queryMerits",
    method: "post",
    data,
  });
}

/** 修改绩效信息 */
export function updatePerformanceInfo(data) {
  return request({
    url: "/updateMerits",
    method: "post",
    data,
  });
}

/** 删除绩效信息 */
export function delPerformanceInfo(data) {
  return request({
    url: "/deleteMerits",
    method: "post",
    data,
  });
}

/** 查询代办绩效列表数据 */
export function fetchPendingList(data) {
  return request({
    url: "/queryMeritsPendingByPage",
    method: "post",
    data,
  });
}

/** 查询已办绩效列表数据 */
export function fetchProcessedList(data) {
  return request({
    url: "/queryMeritsProcessedByPage",
    method: "post",
    data,
  });
}
