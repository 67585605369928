<template>
  <div>
    <el-dialog
      ref="dialog"
      v-loading="dialogLoading"
      :visible.sync="dialogVisible"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(255, 255, 255, 0.8)"
      title="绩效录入"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :destroy-on-close="true"
      @open="handleInit"
      @closed="handleReset"
    >
      <!-- 督导绩效 -->
      <performance-com
        ref="supervision"
        :source="source"
        subject="督导"
        :data-list="supervisionDataList"
      ></performance-com>

      <!-- 调查绩效 -->
      <performance-com
        ref="investigate"
        :source="source"
        subject="调查"
        :data-list="investigationDataList"
      ></performance-com>

      <!-- 内审绩效 -->
      <performance-com
        ref="internalAudit"
        :source="source"
        subject="内审"
        :data-list="internalAuditDataList"
      ></performance-com>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="success" @click="handleSubmit">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import {
    addPerformanceInfo,
    fetchPerformanceInfo,
    updatePerformanceInfo,
  } from "@/api/workplace/pl/performance";
  import PerformanceCom from "@/views/workplace/components/performanceCom";

  export default {
    components: {
      PerformanceCom,
    },
    data() {
      return {
        // 遮罩显示标识
        dialogVisible: false,
        // loading
        dialogLoading: false,
        // 原数据
        sourceData: null,
        // 新增绩效参数
        queryForm: {
          body: {},
          header: {
            currentTime: "",
            requestId: "",
            sourceType: "site",
          },
        },
        // 绩效查询参数
        performanceParams: {
          body: {},
          header: {
            currentTime: "",
            requestId: "",
            sourceType: "site",
          },
        },
        // 触发来源
        source: "",
        // 表单数组
        formList: ["supervision", "investigate", "internalAudit"],
        // 表单验证结果
        formValidResults: [],
        // 督导绩效数据
        supervisionDataList: [],
        // 调查绩效数据
        investigationDataList: [],
        // 内审绩效数据
        internalAuditDataList: [],
        // 模块绩效ID
        id: null,
        // 模块绩效seqId
        seqId: null,
      };
    },
    computed: {
      userNo: function () {
        return this.$parent.userNo;
      },
      userRoleNo: function () {
        return this.$parent.userRoleNo;
      },
    },
    methods: {
      /** 绩效组件初始化 */
      show(row, source) {
        this.sourceData = row;
        if ("edit" === source) {
          this.source = "edit";
        } else {
          this.source = "add";
        }
        this.dialogVisible = true;
      },
      /** 保存提交 */
      handleSubmit() {
        // 校验所有的表单
        this.formList.forEach((item) => {
          this.handleValidateForm(item);
        });
        Promise.all(this.formValidResults)
          .then(async () => {
            this.queryForm.body = {
              ...this.handleGetData(
                "supervision",
                "supervision",
                "supervisionList"
              ),
              ...this.handleGetData(
                "investigate",
                "investigation",
                "investigationList"
              ),
              ...this.handleGetData(
                "internalAudit",
                "internalAudit",
                "internalAuditList"
              ),
            };
            this.queryForm.body.workOrderNo = this.sourceData.workOrderNo;
            this.queryForm.body.userNo = this.userNo;
            this.queryForm.body.userRoleNo = this.userRoleNo;
            // 通过新建进行提交
            if (this.source === "add") {
              try {
                const { header } = await addPerformanceInfo(this.queryForm);
                if (header.retCode === "1") {
                  this.$message.success(header.retMessage);
                  this.$emit("data-update");
                  this.dialogVisible = false;
                } else {
                  this.$message.error(header.retMessage);
                }
              } catch (error) {
                this.$message.error(error);
              }
              // 通过编辑进行提交
            } else {
              try {
                this.queryForm.body.id = this.id;
                this.queryForm.body.seqId = this.seqId;
                const { header } = await updatePerformanceInfo(this.queryForm);
                if (header.retCode === "1") {
                  this.$message.success(header.retMessage);
                  this.$emit("data-update");
                  this.dialogVisible = false;
                } else {
                  this.$message.error(header.retMessage);
                }
              } catch (error) {
                this.$message.error(error);
              }
            }
          })
          .catch((error) => {
            this.$message.warning(error);
          });
        this.formValidResults = [];
      },
      /** 表单验证 */
      handleValidateForm(formName) {
        let self = this;
        const formResult = new Promise((resolve, reject) => {
          self.$refs[formName].$refs.performanceForm.validate((valid) => {
            if (valid) {
              resolve();
            } else {
              reject("总绩效不可为空！");
            }
          });
        });
        const tableResult = this.$refs[formName].validateItem();
        this.formValidResults.push(formResult);
        this.formValidResults.push(tableResult);
      },
      /** 获取绩效模块数据 */
      handleGetData(node, ...args) {
        let params = {};
        const data = this.$refs[node].getData();
        params[args[0]] = data.total;
        params[args[1]] = data.list;
        return params;
      },
      /** 初始化 */
      async handleInit() {
        this.dialogLoading = true;
        this.performanceParams.body.userNo = this.userNo;
        this.performanceParams.body.userRoleNo = this.userRoleNo;
        this.performanceParams.body.workOrderNo = this.sourceData.workOrderNo;
        if ("edit" === this.source) {
          const { header, body } = await fetchPerformanceInfo(
            this.performanceParams
          );
          if (header.retCode === "1") {
            this.$refs.supervision.performanceForm.totalPerformance =
              body.supervision;
            this.$refs.investigate.performanceForm.totalPerformance =
              body.investigation;
            this.$refs.internalAudit.performanceForm.totalPerformance =
              body.internalAudit;
            this.supervisionDataList = body.supervisionList ?? [];
            this.investigationDataList = body.investigationList ?? [];
            this.internalAuditDataList = body.internalAuditList ?? [];
            this.id = body.id;
            this.seqId = body.seqId;
          } else {
            this.$message.error(header.retMessage);
            return;
          }
          this.$emit("dialog-ready");
        }
        this.dialogLoading = false;
      },
      /** 重置 */
      handleReset() {
        this.id = null;
        this.seqId = null;
        this.queryForm = this.$options.data().queryForm;
        this.$refs.supervision.performanceForm.totalPerformance = 0;
        this.$refs.investigate.performanceForm.totalPerformance = 0;
        this.$refs.internalAudit.performanceForm.totalPerformance = 0;
        this.supervisionDataList = [];
        this.investigationDataList = [];
        this.internalAuditDataList = [];
        this.$refs.supervision.$refs.performanceForm.resetFields();
        this.$refs.investigate.$refs.performanceForm.resetFields();
        this.$refs.internalAudit.$refs.performanceForm.resetFields();
      },
    },
  };
</script>

<style></style>
