<template>
  <div class="table-container">
    <!-- 条件查询 -->
    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="12" :lg="24" :xl="24">
        <el-card>
          <el-button icon="el-icon-search" plain @click="handleQuery">
            查询
          </el-button>
          <el-button
            icon="el-icon-refresh-left"
            type="info"
            plain
            @click="handleReset"
          >
            重置
          </el-button>

          <el-form
            ref="queryForm"
            :model="queryForm.body"
            style="margin-top: 15px"
            class="demo-form-inline my-form-class"
          >
            <el-row :gutter="20">
              <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
                <el-form-item prop="workOrderNo">
                  <el-input
                    v-model.trim="queryForm.body.workOrderNo"
                    placeholder="工单编号"
                    :clearable="true"
                    @keyup.enter.native="handleQuery"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
                <el-form-item prop="targetSubject">
                  <el-input
                    v-model.trim="queryForm.body.targetSubject"
                    placeholder="目标主体"
                    :clearable="true"
                    @keyup.enter.native="handleQuery"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
                <el-form-item prop="projectId">
                  <el-cascader
                    v-model="queryForm.body.projectId"
                    :options="projectOptions"
                    :clearable="true"
                    :filterable="true"
                    separator=":"
                    placeholder="项目"
                    @change="handleProjectChange"
                  ></el-cascader>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-card>
      </el-col>
    </el-row>

    <!-- 列表数据 -->
    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="12" :lg="24" :xl="24">
        <el-card>
          <el-table
            v-loading="loading"
            :data="list"
            :element-loading-text="elementLoadingText"
            style="margin-top: 15px"
            border
          >
            <el-table-column
              label="工单编号"
              prop="workOrderNo"
              align="center"
              width="130"
            />
            <el-table-column
              label="目标主体"
              prop="targetSubject"
              align="center"
            />
            <el-table-column label="目标地址" align="center">
              <template v-slot:default="scope">
                {{ scope.row.address || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="项目" align="center">
              <template v-slot:default="scope">
                {{ scope.row.projectName || "-" }}
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              width="120"
              fixed="right"
              align="center"
            >
              <template v-slot="scope">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="添加"
                  placement="top-start"
                >
                  <el-button
                    size="mini"
                    icon="el-icon-plus"
                    type="primary"
                    circle
                    @click="EditPerformanceInfo(scope.row)"
                  ></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
    </el-row>

    <!-- 列表分页 -->
    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="12" :lg="24" :xl="24">
        <el-card>
          <el-pagination
            :current-page="queryForm.body.pageNum"
            :layout="layout"
            :page-size="queryForm.body.pageSize"
            :total="total"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          ></el-pagination>
        </el-card>
      </el-col>
    </el-row>

    <performance-dialog
      ref="performanceDialog"
      @data-update="handleUpdateList"
    ></performance-dialog>
  </div>
</template>

<script>
  import { fetchPendingList } from "@/api/workplace/pl/performance";
  import { getProjectSource } from "@/api/workplace/tools";
  import { getRoleKey } from "@/utils";
  import { mapGetters } from "vuex";
  import PerformanceDialog from "./performanceDialog";

  export default {
    components: { PerformanceDialog },
    data() {
      return {
        // 列表loading
        loading: false,
        // 列表总数
        total: 0,
        // 列表数据
        list: [],
        // 列表请求参数
        queryForm: {
          body: {
            pageNum: 1,
            pageSize: 10,
            workOrderNo: "",
            projectId: "",
            targetSubject: "",
            userNo: "",
            userRoleNo: "",
          },
          header: {
            currentTime: "",
            requestId: "",
            sourceType: "site",
          },
        },
        // 项目请求参数
        projectForm: {
          body: {},
          header: {
            currentTime: "",
            requestId: "",
            sourceType: "site",
          },
        },
        // 项目选项
        projectOptions: [],
        // 列表分页配置项
        layout: "total, sizes, prev, pager, next, jumper",
      };
    },
    computed: {
      ...mapGetters({
        userNo: "user/userNo",
        roles: "user/roles",
      }),
      // 用户的角色信息
      userRoleNo: function () {
        return getRoleKey("PL", this.roles);
      },
    },
    created() {
      this.queryForm.body.userNo = this.userNo;
      this.queryForm.body.userRoleNo = this.userRoleNo;
    },
    methods: {
      /** 数据初始化 */
      handleInitData() {
        this.fetchData();
        this.getProject();
      },
      /** 查询列表 */
      async fetchData() {
        this.loading = true;
        try {
          const { header, body } = await fetchPendingList(this.queryForm);
          if (header.retCode === "1") {
            this.list = body.records;
            this.total = body.total;
            this.$emit("update:todo-val", this.total);
          } else {
            this.$message.error(header.retMessage);
          }
          this.loading = false;
        } catch (error) {
          this.list = [];
          this.loading = false;
        }
      },
      /** 条件查询 */
      handleQuery() {
        this.queryForm.body.pageNum = 1;
        this.fetchData();
      },
      /** 重置查询条件 */
      handleReset() {
        this.$refs["queryForm"].resetFields();
      },
      /** 有数据提交 */
      handleUpdateList() {
        this.handleQuery();
        this.$emit("update-done");
      },
      /** 数据展示数量改变 */
      handleSizeChange(val) {
        this.queryForm.body.pageSize = val;
        this.fetchData();
      },
      /** 页码改变 */
      handleCurrentChange(val) {
        this.queryForm.body.pageNum = val;
        this.fetchData();
      },
      /** 获取项目信息 */
      async getProject() {
        try {
          const { header, body } = await getProjectSource(this.projectForm);
          if (header.retCode === "1") {
            this.projectOptions = body;
          } else {
            this.$message.error(
              `${header.retMessage},获取项目失败，请重新刷新页面`
            );
          }
        } catch (error) {
          this.$message.error(`获取项目失败，${error}`);
        }
      },
      /** 选中项目改变 */
      handleProjectChange(value) {
        if (value.length === 0) {
          this.queryForm.body.projectId = "";
        } else {
          this.queryForm.body.projectId = value[1];
        }
      },
      /** 编辑绩效信息 */
      EditPerformanceInfo(data) {
        this.$refs.performanceDialog.show(data, "add");
      },
    },
  };
</script>

<style></style>
