<template>
  <div class="table-container">
    <!-- 导航查询模板 -->
    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="12" :lg="24" :xl="24">
        <el-card>
          <el-button
            icon="el-icon-search"
            plain
            native-type="submit"
            @click="handleQuery"
          >
            查询
          </el-button>
          <el-button
            icon="el-icon-refresh-left"
            type="info"
            plain
            native-type="submit"
            @click="resetForm('queryForm')"
          >
            重置
          </el-button>
          <el-form
            ref="queryForm"
            :model="queryForm"
            style="margin-top: 15px"
            class="demo-form-inline my-form-class"
          >
            <el-row :gutter="20">
              <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                <el-form-item prop="workOrderNo">
                  <el-input
                    v-model.number.trim="queryForm.workOrderNo"
                    placeholder="工单编号"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
              <!-- 目标主体 -->
              <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                <el-form-item prop="targetSubject">
                  <el-input
                    v-model.trim="queryForm.targetSubject"
                    placeholder="目标主体"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
              <!-- 项目 -->
              <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                <el-form-item prop="projectId">
                  <el-cascader
                    :value="queryForm.projectId"
                    :options="projectInfo"
                    :filterable="true"
                    clearable
                    separator=":"
                    placeholder="项目"
                    @change="handleProjectChange"
                  ></el-cascader>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
    <!--列表模块-->
    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="12" :lg="24" :xl="24">
        <el-card>
          <el-button type="primary" @click="handleEdit({}, true)">
            添加统计
          </el-button>
          <el-table
            ref="tableSort"
            v-loading="listLoading"
            :data="list"
            :element-loading-text="elementLoadingText"
            style="margin-top: 15px"
            border
            @selection-change="setSelectRows"
          >
            <el-table-column
              label="工单编号"
              prop="workOrderNo"
              align="center"
              width="130"
            ></el-table-column>
            <el-table-column
              prop="target"
              label="目标主体"
              width="150"
            ></el-table-column>
            <el-table-column label="目标地址" min-width="250" prop="address">
              <template slot-scope="scope">
                {{ scope.row.address || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="项目" prop="project" width="150">
              <template slot-scope="scope">
                {{ scope.row.project || "-" }}
              </template>
            </el-table-column>
            <el-table-column
              label="录入时间"
              prop="createTime"
              align="center"
              min-width="130"
            ></el-table-column>
            <el-table-column
              label="报销总金额(元)"
              min-width="140"
              prop="amount"
            >
              <template slot-scope="scope">
                {{ scope.row.amount || "-" }}
              </template>
            </el-table-column>
            <el-table-column
              label="快递公司"
              min-width="140"
              prop="courierCompany"
            >
              <template slot-scope="scope">
                {{ scope.row.courierCompany || "-" }}
              </template>
            </el-table-column>
            <el-table-column
              label="快递单号"
              min-width="140"
              prop="courierNumber"
            >
              <template slot-scope="scope">
                {{ scope.row.courierNumber || "-" }}
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              width="80px"
              fixed="right"
              align="center"
            >
              <template slot-scope="scope">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="编辑"
                  placement="top-start"
                >
                  <el-button
                    size="mini"
                    type="primary"
                    circle
                    icon="el-icon-edit-outline"
                    @click="handleEdit(scope.row, false)"
                  ></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
    </el-row>

    <!--分页模块-->
    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="12" :lg="24" :xl="24">
        <el-card>
          <el-pagination
            :background="background"
            :current-page="queryForm.pageNum"
            :layout="layout"
            :page-size="queryForm.pageSize"
            :total="total"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          ></el-pagination>
        </el-card>
      </el-col>
    </el-row>
    <!--编辑工单-->
    <edit ref="edit" @refreshData="refreshData" @fetchData="fetchData"></edit>
  </div>
</template>

<script>
  import { querystatisticalListPage } from "@/api/workplace/investigate/statistical";
  import { returnBitTimestamp, getRoleKey } from "@/utils/index";
  import edit from "./edit";
  import { mapGetters } from "vuex";
  import {
    getBlock,
    getGeneralReason,
    getProjectSource,
    querySystemUsers,
  } from "@/api/workplace/tools";
  import { export_json_to_excel } from "@/vendor/ExportExcel";

  export default {
    name: "IndexVue",
    components: {
      edit,
    },
    filters: {
      statusFilter(status) {
        const statusMap = {
          published: "success",
          draft: "gray",
          deleted: "danger",
        };
        return statusMap[status];
      },
    },
    data() {
      return {
        transferTitle: ["选择员工", "已选择 1 个，还可以选 0 个"],
        mode: "transfer",
        isShow: false,
        imgShow: true,
        list: [],
        imageList: [],
        listLoading: true,
        layout: "total, sizes, prev, pager, next, jumper",
        total: 0,
        background: true,
        selectRows: "",
        elementLoadingText: "正在加载...",
        queryForm: {
          dateMonth: null,
          workOrderNo: null,
          targetSubject: null,
          projectId: null,
          pageNum: 1,
          pageSize: 10,
        },
        // 查询人员推荐列表参数
        param: {
          body: {},
          header: {
            currentTime: "2020-09-09 12:12:12",
            requestId: "1",
            sourceType: "site",
          },
        },
        // 选择人员
        staffInfo: [],
        staffForm: {
          body: {},
          header: {
            currentTime: "",
            requestId: "123",
            sourceType: "site",
          },
        },

        // 项目
        projectInfo: [],
        projectForm: {
          body: {
            source: "",
          },
          header: {
            currentTime: "",
            requestId: "123",
            sourceType: "site",
          },
        },
        //   角色人员
        roleOptions: [],
        roleOptions_arr: [],
        roleForm: {
          body: {},
          header: {
            currentTime: "",
            requestId: "123",
            sourceType: "site",
          },
        },

        cityInfo: [],
        cityForm: {
          body: {},
          header: {
            currentTime: "",
            requestId: "123",
            sourceType: "site",
          },
        },
        reasonOptions: [],
        downloadLoading: false,
      };
    },
    computed: {
      ...mapGetters({
        userNo: "user/userNo",
        roles: "user/roles",
        accessKeyId: "user/accessKeyId",
        accessKeySecret: "user/accessKeySecret",
        bucketName: "user/bucketName",
        endpoint: "user/endpoint",
        endpoinxBak: "user/endpoinxBak",
        OSSClient: "user/OSSClient",
        region: "user/region",
        policy: "user/policy",
        signature: "user/signature",
      }),
    },
    created() {
      this.param.body.userNo = this.userNo;
      this.param.body.userRoleNo = getRoleKey("investigate", this.roles);
    },
    beforeDestroy() {},
    mounted() {
      this.handleInitData();
    },
    methods: {
      /** 数据初始化 */
      handleInitData() {
        this.fetchData();
        // 获取项目
        this.getProject();
      },
      /** 导出Excel */
      handleDownload() {
        if (this.selectRows.length > 0) {
          this.downloadLoading = true;
          const tHeader = [
            "工单编号",
            "目标主体",
            "目标地址",
            "项目",
            "到期时间(天)",
            "流转日期",
            "预约人员",
            "环节/整体滞留(天)",
          ];
          const filterVal = [
            "workOrderNo",
            "targetSubject",
            "address",
            "projectName",
            "maturityDate",
            "circulationTime",
            "bookUserName",
            "stepAndStranded",
          ];
          const list = this.selectRows;
          const data = this.formatJson(filterVal, list);
          export_json_to_excel({
            header: tHeader,
            data,
            filename: "工单列表",
          });
          this.downloadLoading = false;
        } else {
          this.$baseMessage("未选中任何行", "error");
          return false;
        }
      },
      /** 组装导出数据 */
      formatJson(filterVal, jsonData) {
        return jsonData.map((v) =>
          filterVal.map((j) => {
            if (!v[j]) {
              return "—";
            }
            if ("circulationTime" === j && v["circulationTime"]) {
              //格式化下单日期
              return eighthBitTimestamp(Date.parse(v["circulationTime"]));
            }
            return v[j];
          })
        );
      },
      // 刷新数据
      refreshData() {
        /* 添加tab标签  传参 */
        let refresh_data = {
          todo_total: this.total,
          isDone: true,
        };
        this.$emit("child-todo", refresh_data);
        this.fetchData();
      },
      setSelectRows(val) {
        this.selectRows = val;
      },
      // 重置
      resetForm(formName) {
        if (this.$refs[formName] !== undefined) {
          this.$refs[formName].resetFields();
          this.queryForm = this.$options.data().queryForm;
        }
      },
      handleEdit(row, isAdd) {
        row.userNo = this.userNo;
        /** oss配置 */
        row.accessKeyId = this.accessKeyId;
        row.accessKeySecret = this.accessKeySecret;
        row.bucketName = this.bucketName;
        row.endpoint = this.endpoint;
        row.endpoinxBak = this.endpoinxBak;
        row.OSSClient = this.OSSClient;
        row.region = this.region;
        row.policy = this.policy;
        row.signature = this.signature;
        this.$refs["edit"].showEdit(row, isAdd);
      },

      handleSizeChange(val) {
        this.queryForm.pageSize = val;
        this.fetchData();
      },
      handleCurrentChange(val) {
        this.queryForm.pageNum = val;
        this.fetchData();
      },
      heightQuery() {
        this.isShow = !this.isShow;
        // 展开时查询选项的下拉数据
        if (this.isShow) {
          // 获取地区
          this.getAddress();
          // 获取普通退回原因
          this.getNormalReason();
        }
      },
      async getNormalReason() {
        this.reasonOptions = Object.assign(
          {},
          (await getGeneralReason(this.backReasonParam)).body
        );
      },
      handleQuery() {
        this.queryForm.pageNum = 1;
        this.fetchData();
      },
      // 获取人员推荐列表分页信息
      async fetchData() {
        this.listLoading = true;
        this.param.body = Object.assign(this.param.body, this.queryForm);
        this.param.body.workOrderNo =
          this.param.body.workOrderNo === ""
            ? null
            : this.param.body.workOrderNo;
        this.param.body.projectId =
          this.param.body.projectId === "" ? null : this.param.body.projectId;
        this.param.body.targetSubject =
          this.param.body.targetSubject === ""
            ? null
            : this.param.body.targetSubject;
        const { body } = await querystatisticalListPage(this.param);
        this.list = body.records;
        this.total = body.total;
        let refresh_data = {
          todo_total: this.total,
          isDone: false,
        };
        this.$emit("child-todo", refresh_data);
        this.listLoading = false;
      },

      // 表格显色
      tableRowClassName({ row }) {
        // 到期时间小于3，行内高亮
        if (row.maturityDate <= 3) {
          return "warning-row";
        } else return "";
      },
      // 获取项目
      getProject() {
        this.projectForm.header.currentTime = returnBitTimestamp(new Date());
        getProjectSource(this.projectForm).then((response) => {
          if ("1" === response.header.retCode) {
            this.projectInfo = response.body;
          }
        });
      },
      // 选择项目
      handleProjectChange(value) {
        this.queryForm.projectId = value[1];
      },
    },
  };
</script>
<style scoped>
  .el-col {
    margin-bottom: 10px;
  }
  .el-col :last-child {
    margin-bottom: 0;
  }

  .el-date-editor--daterange.el-input,
  .el-date-editor--daterange.el-input__inner,
  .el-date-editor--timerange.el-input,
  .el-date-editor--timerange.el-input__inner {
    width: 100%;
  }

  .el-select,
  .el-cascader {
    width: 100%;
  }

  .el-dropdown-menu__item {
    padding: 0;
  }
  .el-table /deep/.warning-row {
    background: #f8b7bc;
  }
  .my-form-class {
    margin-bottom: -10px !important;
  }
</style>
<style>
  .my-back-img {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 4px;
    left: 2px;
  }
</style>
