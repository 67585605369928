var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { background: "#f6f8f9" } },
    [
      _c("div", { staticStyle: { "margin-bottom": "10px" } }, [
        _vm._v(_vm._s(_vm.companyName)),
      ]),
      _c(
        "el-tabs",
        {
          attrs: { type: "border-card", "tab-position": _vm.tabPosition },
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "取证资料", name: "evidence" } },
            [
              _vm.object.projectSign == null
                ? _c("evidence", {
                    ref: "evidence",
                    attrs: { row: _vm.object },
                  })
                : _c("evidence-history", {
                    ref: "evidenceHistory",
                    attrs: { row: _vm.object },
                  }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "拍摄照片", name: "photo" } },
            [
              _c("photo", {
                ref: "photo",
                attrs: { type: "50", row: _vm.object },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "拍摄录像", name: "screenVideo" } },
            [
              _c("screenVideo", {
                ref: "screenVideo",
                attrs: { type: "51", row: _vm.object },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "扫描文件", name: "scanFile" } },
            [
              _c("scanFile", {
                ref: "scanFile",
                attrs: { type: "52", row: _vm.object },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "录制音频", name: "screenAudio" } },
            [
              _c("screenAudio", {
                ref: "screenAudio",
                attrs: { type: "53", row: _vm.object },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "会议信息", name: "meeting" } },
            [
              _c("meeting", {
                ref: "meeting",
                attrs: { type: "57", row: _vm.object },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "标注记录", name: "labelRecord" } },
            [
              _c("labelRecord", {
                ref: "labelRecord",
                attrs: { type: "56", row: _vm.object },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "企业信息", name: "company" } },
            [_c("company", { ref: "company", attrs: { row: _vm.object } })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }