var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-main-container" },
    [
      _c("h3", [_vm._v(_vm._s(_vm.subject) + "绩效")]),
      _c(
        "el-form",
        {
          ref: "performanceForm",
          attrs: {
            model: _vm.performanceForm,
            rules: _vm.rules,
            inline: true,
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: `${_vm.subject}总绩效`,
                prop: "totalPerformance",
              },
            },
            [
              _c("el-input", {
                attrs: { disabled: _vm.noAction, clearable: true },
                on: {
                  change: function ($event) {
                    return _vm.handleInputChange(
                      _vm.performanceForm.totalPerformance
                    )
                  },
                },
                nativeOn: {
                  keyup: function ($event) {
                    _vm.performanceForm.totalPerformance =
                      _vm.performanceForm.totalPerformance.replace(
                        _vm.performanceForm.totalPerformance,
                        _vm.handleRestricted(
                          _vm.performanceForm.totalPerformance
                        )
                      )
                  },
                },
                model: {
                  value: _vm.performanceForm.totalPerformance,
                  callback: function ($$v) {
                    _vm.$set(_vm.performanceForm, "totalPerformance", $$v)
                  },
                  expression: "performanceForm.totalPerformance",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              !_vm.noAction
                ? _c("el-button", { on: { click: _vm.handleAdd } }, [
                    _vm._v("新增"),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        { attrs: { data: _vm.dataList, border: "" } },
        [
          _c("el-table-column", {
            attrs: {
              type: "index",
              label: "序号",
              align: "center",
              width: "50",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "姓名", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    !_vm.noAction
                      ? _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: `请选择${_vm.subject}人员`,
                              clearable: true,
                              filterable: "",
                            },
                            model: {
                              value: scope.row.username,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "username", $$v)
                              },
                              expression: "scope.row.username",
                            },
                          },
                          _vm._l(_vm.options, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value },
                            })
                          }),
                          1
                        )
                      : _c("el-input", {
                          attrs: { readonly: _vm.noAction },
                          model: {
                            value: scope.row.usernameValue,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "usernameValue", $$v)
                            },
                            expression: "scope.row.usernameValue",
                          },
                        }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "绩效", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-input", {
                      attrs: {
                        placeholder: "请输入绩效",
                        clearable: true,
                        readonly: _vm.noAction,
                      },
                      on: {
                        change: function ($event) {
                          return _vm.handleInputChange(scope.row.merits)
                        },
                      },
                      nativeOn: {
                        keyup: function ($event) {
                          scope.row.merits = scope.row.merits.replace(
                            scope.row.merits,
                            _vm.handleRestricted(scope.row.merits)
                          )
                        },
                      },
                      model: {
                        value: scope.row.merits,
                        callback: function ($$v) {
                          _vm.$set(scope.row, "merits", $$v)
                        },
                        expression: "scope.row.merits",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          !_vm.noAction
            ? _c("el-table-column", {
                attrs: { label: "操作", align: "center", width: "120" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "danger" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleDelete(scope.row)
                                },
                              },
                            },
                            [_vm._v(" 删除 ")]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2392350464
                ),
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }