<template>
  <div>
    <el-card class="box-card">
      <!--工单进度-->
      <order-process :param="param"></order-process>
      <!--基础信息>-->
      <div class="title">基础信息</div>

      <el-table :data="tableData" stripe border>
        <el-table-column
          prop="workOrderNo"
          label="工单编号"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="projectNameNo"
          label="Project Name"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="projectName"
          label="项目"
          align="center"
        ></el-table-column>
      </el-table>
      <el-table :data="tableData" stripe border>
        <el-table-column
          prop="targetSubject"
          label="目标主体"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="address"
          label="国家/省份/城市/地域（县级市）"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="targetAddr1"
          label="目标地址1"
          align="center"
        ></el-table-column>
      </el-table>
      <el-table :data="tableData" stripe border>
        <el-table-column
          prop="targetAddr2"
          label="目标地址2"
          align="center"
        ></el-table-column>
        <el-table-column prop="targetContact" label="目标联系人" align="center">
          <template slot-scope="scope">
            {{ scope.row.targetContact || "-" }}
          </template>
        </el-table-column>

        <el-table-column prop="contactNumber" label="联系电话" align="center">
          <template slot-scope="scope">
            {{ scope.row.contactNumber || "-" }}
          </template>
        </el-table-column>
      </el-table>

      <el-table :data="tableData" stripe border>
        <el-table-column prop="duty" label="职务" align="center">
          <template slot-scope="scope">
            {{ scope.row.duty || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="orderTime" label="下单时间" align="center">
          <template slot-scope="scope">
            {{ scope.row.orderTime || "-" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="innerDeadline"
          label="内部截止日期"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.innerDeadline || "-" }}
          </template>
        </el-table-column>
      </el-table>
      <el-table :data="tableData" stripe border>
        <el-table-column prop="remark1" label="备注1" align="center">
          <template slot-scope="scope">
            {{ scope.row.remark1 || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="remark2" label="备注2" align="center">
          <template slot-scope="scope">
            {{ scope.row.remark2 || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="remark3" label="备注3" align="center">
          <template slot-scope="scope">
            {{ scope.row.remark3 || "-" }}
          </template>
        </el-table-column>
      </el-table>
      <el-table :data="tableData" stripe border>
        <el-table-column
          prop="clientFullName"
          label="委托方全称"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.clientFullName || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="clientAbbr" label="委托方简称" align="center">
          <template slot-scope="scope">
            {{ scope.row.clientAbbr || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="reportTimeLimit" label="报告时限" align="center">
          <template slot-scope="scope">
            {{ scope.row.reportTimeLimit || "-" }}
          </template>
        </el-table-column>
      </el-table>

      <!--附加信息-->
      <div class="title">附加信息</div>
      <el-table :data="tableData" stripe border>
        <el-table-column
          prop="expectVisitDate"
          label="预计走访日期"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.expectVisitDate || "-" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="expectServeyDay"
          label="预计所需调查天数"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.expectServeyDay || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="needUser" label="所需人手" align="center">
          <template slot-scope="scope">
            {{ scope.row.needUser || "-" }}
          </template>
        </el-table-column>
      </el-table>
      <el-table :data="tableData" stripe border>
        <el-table-column
          prop="expectHour"
          label="预计需时（小时）"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.expectHour || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="registerAddress" label="注册地址" align="center">
          <template slot-scope="scope">
            {{ scope.row.registerAddress || "-" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="targetSubjectRegStatus"
          label="目标主体注册状态"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.targetSubjectRegStatus || "-" }}
          </template>
        </el-table-column>
      </el-table>
      <el-table :data="tableData" stripe border>
        <el-table-column prop="visitFee" label="走访收费" align="center">
          <template slot-scope="scope">
            {{ scope.row.visitFee || "-" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="urgentVisitFee"
          label="加急走访收费"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.urgentVisitFee || "-" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="againVisitFee"
          label="再次走访收费"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.againVisitFee || "-" }}
          </template>
        </el-table-column>
      </el-table>
      <el-table :data="tableData" stripe border>
        <el-table-column prop="feeBasis" label="收费依据" align="center">
          <template slot-scope="scope">
            {{ scope.row.feeBasis || "-" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="targetOrganization1"
          label="目标机构1"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.targetOrganization1 || "-" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="targetOrganization2"
          label="目标机构2"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.targetOrganization2 || "-" }}
          </template>
        </el-table-column>
      </el-table>
      <el-table :data="tableData" stripe border>
        <el-table-column
          prop="targetResult"
          label="目标地址预核结果"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.targetResult || "-" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="infeasibleReason"
          label="不可行原因/待确认项目"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.infeasibleReason || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="mailHeader" label="邮件抬头" align="center">
          <template slot-scope="scope">
            {{ scope.row.mailHeader || "-" }}
          </template>
        </el-table-column>
      </el-table>
      <!--特别信息-->
      <div class="title">特别信息</div>

      <el-table :data="tableData" stripe border>
        <el-table-column prop="plSpecialMsg" label="PL特别留言" align="center">
          <template slot-scope="scope">
            {{ scope.row.plSpecialMsg || "-" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="bookSpecialMsg"
          label="预约特别留言"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.bookSpecialMsg || "-" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="monitorSpecialMsg"
          label="督导特别留言"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.monitorSpecialMsg || "-" }}
          </template>
        </el-table-column>
      </el-table>

      <!--附件-->
      <div v-show="annexGroup.length !== 0" class="title">附件</div>

      <el-row style="text-align: center; margin-top: 20px">
        <div v-for="(item, index) in annexGroup" :key="index">
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
            <a
              @click.stop.prevent="
                handleDownload({ name: item.fileName, path: item.path })
              "
            >
              <img src="@/icon/annex.svg" alt="" width="80" height="80" />
              <p>{{ item.fileName }}</p>
            </a>
          </el-col>
        </div>
      </el-row>

      <!--操作信息-->
      <div class="title">操作信息</div>
      <el-table :data="operationInfo" stripe border>
        <el-table-column prop="operator" label="操作者" align="center">
          <template slot-scope="scope">
            {{ scope.row.operator || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="role" label="角色" align="center">
          <template slot-scope="scope">
            {{ scope.row.role || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="operAction" label="操作状态" align="center">
          <template slot-scope="scope">
            {{ scope.row.operAction || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="操作时间" align="center">
          <template slot-scope="scope">
            {{ scope.row.createTime || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注" align="center">
          <template slot-scope="scope">
            {{ scope.row.remark || "-" }}
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <el-card>
      <h3>绩效详情</h3>
      <!-- 督导绩效 -->
      <performance-com
        ref="supervision"
        :no-action="true"
        subject="督导"
        :data-list="supervisionDataList"
      ></performance-com>

      <!-- 调查绩效 -->
      <performance-com
        ref="investigate"
        :no-action="true"
        subject="调查"
        :data-list="investigationDataList"
      ></performance-com>

      <!-- 内审绩效 -->
      <performance-com
        ref="internalAudit"
        :no-action="true"
        subject="内审"
        :data-list="internalAuditDataList"
      ></performance-com>
    </el-card>
    <el-card>
      <h3>统计录入</h3>
      <statisticalView
        ref="statisticalView"
        @refreshData="refreshData"
      ></statisticalView>
    </el-card>
    <el-card>
      <h3>成本录入</h3>
      <costentryView
        ref="costentryView"
        @refreshData="refreshData"
      ></costentryView>
    </el-card>
  </div>
</template>

<script>
  import orderProcess from "@/views/workplace/components/orderProcess";
  import PerformanceCom from "@/views/workplace/components/performanceCom";

  import { fetchPerformanceInfo } from "@/api/workplace/pl/performance";

  import statisticalView from "@/views/workplace/investigate/statistical/statisticalView";
  import costentryView from "@/views/workplace/supervision/costentry/components/costentryView";

  import {
    getOperationInfo,
    getOrderDetail,
    searchAttach,
  } from "@/api/workplace/tools";
  import {
    getRoleKey,
    returnBitTimestamp,
    downloadFile,
    isExistOSS,
  } from "@/utils";
  import { mapGetters } from "vuex";

  export default {
    name: "Detail",
    components: {
      orderProcess,
      PerformanceCom,
      statisticalView,
      costentryView,
    },
    data() {
      return {
        annexGroup: [],
        orderNo: null,
        tabPosition: "top",
        tableData: [],
        // 获取工单详情
        param: {
          body: {
            userNo: "",
            userRoleNo: "",
            workOrderNo: null,
            pageSize: 10,
            pageNum: 1,
          },
          header: {
            currentTime: "2020-09-09 12:12:12",
            requestId: "1",
            sourceType: "site",
          },
        },
        operationInfo: [],
        // 查询工单的操作日志
        logParam: {
          body: {
            workOrderNo: "",
          },
          header: {
            currentTime: "2020-09-09 12:12:12",
            requestId: "1",
            sourceType: "site",
          },
        },
        // 给子组件传值
        object: {
          userNo: null,
          userRoleNo: null,
          workOrderNo: null,
          seqId: null,
          targetSubject: null,
        },
        // 绩效查询参数
        performanceParams: {
          body: {},
          header: {
            currentTime: "",
            requestId: "",
            sourceType: "site",
          },
        },
        // 督导绩效数据
        supervisionDataList: [],
        // 调查绩效数据
        investigationDataList: [],
        // 内审绩效数据
        internalAuditDataList: [],
      };
    },
    computed: {
      ...mapGetters({
        userNo: "user/userNo",
        roles: "user/roles",
        OSSClient: "user/OSSClient",
        endpoinxBak: "user/endpoinxBak",
      }),
    },
    created() {
      this.param.body.userNo = this.userNo;
      this.param.body.userRoleNo = getRoleKey("qc", this.roles);
      this.showDetail();
      /** 查询附件信息 */
      this.attach();
      this.handleFetchPerformanceDetail();
    },
    methods: {
      /** 查询附件信息 */
      async attach() {
        this.param.body.workOrderNo = this.$route.query.workOrderNo;
        this.param.header.currentTime = returnBitTimestamp(new Date());
        const { body, header } = await searchAttach(this.param);
        if (header.retCode === "1") {
          this.annexGroup = body;
        } else {
          this.$baseMessage(header.retMessage, "error");
        }
      },
      async showDetail() {
        this.tableData = [];
        this.object.projectId = this.$route.query.projectId;
        this.orderNo = this.$route.query.workOrderNo;
        this.object.userNo = this.$route.query.userNo;
        this.object.userRoleNo = this.$route.query.userRoleNo;
        this.object.workOrderNo = this.$route.query.workOrderNo;
        this.object.seqId = this.$route.query.seqId;
        this.object.targetSubject = this.$route.query.target;
        this.object.endpoinxBak = this.endpoinxBak;
        this.object.OSSClient = this.OSSClient;
        this.object.workplace = "qc";
        this.object.flag = "detail";

        // 获取工单详情
        this.param.body.workOrderNo = this.$route.query.workOrderNo;
        this.tableData.push(
          Object.assign({}, (await getOrderDetail(this.param)).body)
        );
        // 获取操作工单日志信息
        this.logParam.body.workOrderNo = this.$route.query.workOrderNo;
        this.operationInfo = (await getOperationInfo(this.logParam)).body;
        this.showStatistical();
      },
      showStatistical() {
        var row = {};
        row.workOrderNo = this.$route.query.workOrderNo;
        row.seqId = this.$route.query.seqId;

        row.userNo = this.userNo;
        /** oss配置 */
        row.accessKeyId = this.accessKeyId;
        row.accessKeySecret = this.accessKeySecret;
        row.bucketName = this.bucketName;
        row.endpoint = this.endpoint;
        row.endpoinxBak = this.endpoinxBak;
        row.OSSClient = this.OSSClient;
        row.region = this.region;
        row.policy = this.policy;
        row.signature = this.signature;
        this.$nextTick(() => {
          this.$refs["statisticalView"].showEdit(row, false);
          this.$refs["costentryView"].showEdit(row, false);
        });
      },
      /** 附件下载 */
      handleDownload(item) {
        isExistOSS(this.OSSClient, item.path)
          .then(() => {
            downloadFile(item);
          })
          .catch(() => {
            this.$message.error("当前文件不存在！");
          });
      },
      /** 查询绩效信息 */
      async handleFetchPerformanceDetail() {
        this.performanceParams.body.userNo = this.$route.query.plUser;
        this.performanceParams.body.userRoleNo = getRoleKey(
          "manager",
          this.roles
        );
        this.performanceParams.body.workOrderNo = this.$route.query.workOrderNo;
        const { header, body } = await fetchPerformanceInfo(
          this.performanceParams
        );
        if (header.retCode === "1") {
          this.$refs.supervision.performanceForm.totalPerformance =
            body.supervision;
          this.$refs.investigate.performanceForm.totalPerformance =
            body.investigation;
          this.$refs.internalAudit.performanceForm.totalPerformance =
            body.internalAudit;
          this.supervisionDataList = body.supervisionList ?? [];
          this.investigationDataList = body.investigationList ?? [];
          this.internalAuditDataList = body.internalAuditList ?? [];
        } else {
          this.$message.error(header.retMessage);
        }
      },
    },
  };
</script>

<style>
  body {
    font-weight: 700;
    color: #666;
  }
  .edit_title .title {
    margin: 10px 0;
  }
  .el-step__title {
    font-size: 14px;
  }
  .el-step__title.is-process {
    font-weight: bold;
    color: #666;
  }
  html body .el-table th .cell {
    font-weight: 700;
    color: #666;
  }

  .el-form-item__label {
    font-weight: normal;
  }

  .el-date-editor.el-input,
  .el-date-editor.el-input__inner,
  .el-date-editor--daterange.el-input,
  .el-date-editor--daterange.el-input__inner,
  .el-date-editor--timerange.el-input,
  .el-date-editor--timerange.el-input__inner {
    width: 100% !important;
  }

  .el-select,
  .el-cascader {
    width: 100%;
  }

  .myDiv {
    width: 150px;
    height: 150px;
    text-align: center;
  }

  .myDiv img {
    width: 130px;
    height: 130px;
    text-align: center;
  }
</style>
