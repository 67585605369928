<template>
  <div>
    <el-dialog
      :title="title"
      :class="myDialog"
      :visible.sync="editFormVisible"
      :close-on-click-modal="false"
      width="60%"
      @close="handleClose"
    >
      <el-backtop :target="myDialogPoint"></el-backtop>
      <el-form
        ref="editForm"
        class="table-class demo-form-inline"
        :model="editForm"
        :rules="rules"
      >
        <el-row :gutter="80">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-form-item label="工单编号" prop="workOrderNo">
              <el-input
                v-model="editForm.workOrderNo"
                :disabled="!isAdd"
                placeholder="请输入工单编号"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="80">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-form-item label="报销总金额" prop="amount">
              <el-input
                v-model="editForm.amount"
                placeholder="请输入报销总金额"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="80">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-form-item>
              <costadd
                ref="photobx"
                :is-show-file-name="false"
                :type="'50'"
                :row="object"
                :is-show-down-load="false"
              ></costadd>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="80">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-form-item label="快递公司" prop="courierCompany">
              <el-input
                v-model="editForm.courierCompany"
                placeholder="请输入快递公司"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="80">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-form-item label="快递单号" prop="courierNumber">
              <el-input
                v-model="editForm.courierNumber"
                placeholder="请输入快递单号"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="80">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-form-item>
              <costadd
                ref="photodh"
                :is-show-file-name="false"
                :type="'50'"
                :is-show-down-load="false"
                :row="object"
              ></costadd>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button
          v-if="!isAdd"
          type="danger"
          :loading="saveLoading"
          @click="del"
        >
          删 除
        </el-button>
        <el-button type="success" :loading="saveLoading" @click="save">
          保 存
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import { isExistOSS } from "@/utils/index";
  // 拍摄照片
  import costadd from "@/views/workplace/components/costAdd";
  import {
    add,
    update,
    getInfo,
    delInfo,
    checkWorkOrder,
  } from "@/api/workplace/investigate/statistical";
  import { getRoleKey, eighthBitTime, downloadFile } from "@/utils/index";
  import { isNum } from "@/utils/validate";

  export default {
    name: "Edit",
    components: {
      costadd,
    },
    data() {
      const validateNum = (rule, value, callback) => {
        if (value && !isNum(value)) {
          callback(new Error("请输入数字，允许带两位小数"));
        } else if (!(Number(value) <= 99999999.99)) {
          callback(
            new Error("报销总金额必须小于或等于99999999.99，请重新输入！")
          );
        } else {
          callback();
        }
      };
      const validateCheckoutNo = (rule, value, callback) => {
        if (this.isAdd) {
          checkWorkOrder({
            body: {
              userNo: this.editParam.body.userNo,
              userRoleNo: this.editParam.body.userRoleNo,
              workOrderNo: value,
            },
            header: {
              currentTime: "2020-09-09 12:12:12",
              requestId: "1",
              sourceType: "site",
            },
          }).then((res) => {
            if (!res.body) {
              callback(new Error(res.header.retMessage));
            } else {
              callback();
            }
          });
        } else {
          callback();
        }
      };
      return {
        isAdd: true,
        saveLoading: false,
        annexGroup: [],
        title: "",
        myDialog: "",
        myDialogPoint: "",
        editFormVisible: false,
        object: null,
        //编辑给后端的接口参数
        editForm: {
          amount: null,
          courierCompany: null,
          courierNumber: null,
          courierAmountAnnex: [],
          courierNumberAnnex: [],
          delCourierAmountAnnex: [],
          delCourierNumberAnnex: [],
          workOrderNo: null,
        },
        // 获取工单详情
        editParam: {
          body: {
            userNo: "",
            userRoleNo: "",
            workOrderNo: null,
            seqId: null,
          },
          header: {
            currentTime: "2020-09-09 12:12:12",
            requestId: "1",
            sourceType: "site",
          },
        },
        rules: {
          workOrderNo: [
            {
              required: true,
              trigger: "blur",
              message: "请输入工单编号！",
            },
            { validator: validateCheckoutNo, trigger: "blur" },
          ],
          amount: [{ validator: validateNum, trigger: "blur" }],
          // courierCompany: [
          //   {
          //     required: true,
          //     trigger: "blur",
          //     message: "请输入快递公司！",
          //   },
          // ],
          // courierNumber: [
          //   {
          //     required: true,
          //     trigger: "blur",
          //     message: "请输入快递单号！",
          //   },
          // ],
        },
      };
    },
    computed: {
      ...mapGetters({
        userNo: "user/userNo",
        roles: "user/roles",
        endpoinxBak: "user/endpoinxBak",
        OSSClient: "user/OSSClient",
      }),
    },
    created() {
      this.editParam.body.userNo = this.userNo;
      this.editParam.body.userRoleNo = getRoleKey("supervisor", this.roles);
    },
    methods: {
      async showEdit(row, isAdd) {
        this.isAdd = isAdd;
        this.title = isAdd ? "添加统计" : "统计编辑";
        this.myDialog = isAdd ? "myDialogAdd" : "myDialogEdit";
        this.myDialogPoint = isAdd ? ".myDialogAdd" : ".myDialogEdit";
        this.object = row;
        this.editForm.workOrderNo =
          row.workOrderNo === undefined ? "" : row.workOrderNo;
        this.editParam.body.seqId = this.object.seqId;
        //更新当前页面数据
        if (!this.isAdd) {
          const { body, header } = await getInfo({
            body: {
              seqId: this.object.seqId,
              userNo: this.editParam.body.userNo,
              userRoleNo: this.editParam.body.userRoleNo,
              workOrderNo: this.object.workOrderNo,
            },
            header: {
              currentTime: "2020-09-09 12:12:12",
              requestId: "1",
              sourceType: "site",
            },
          });
          this.editFormVisible = true;
          this.$nextTick(() => {
            this.$refs["editForm"].resetFields();
            this.$refs["photobx"].checkAll = false;
            this.$refs["photodh"].checkAll = false;
            this.$refs["photodh"].imgRecordData = [];
            this.$refs["photobx"].imgRecordData = [];
            this.$refs["photodh"].deleteRecordData = [];
            this.$refs["photodh"].addRecordData = [];
            this.$refs["photobx"].deleteRecordData = [];
            this.$refs["photobx"].addRecordData = [];
            this.bindData(body);
          });
        } else {
          this.editFormVisible = true;
          this.$nextTick(() => {
            this.$refs["editForm"].resetFields();
            this.editForm.workOrderNo = null;
            this.$refs["photobx"].checkAll = false;
            this.$refs["photodh"].checkAll = false;
            this.$refs["photodh"].imgRecordData = [];
            this.$refs["photobx"].imgRecordData = [];
            this.$refs["photodh"].deleteRecordData = [];
            this.$refs["photodh"].addRecordData = [];
            this.$refs["photobx"].deleteRecordData = [];
            this.$refs["photobx"].addRecordData = [];
          });
        }
      },
      bindData(body) {
        this.editForm.workOrderNo = this.object.workOrderNo;
        this.editParam.body.seqId = this.object.seqId;
        this.editForm.amount = body.amount;
        this.editForm.courierCompany = body.courierCompany;
        this.editForm.courierNumber = body.courierNumber;
        if (
          body.courierCompanyAnnex !== undefined &&
          null !== body.courierCompanyAnnex
        ) {
          this.editForm.courierAmountAnnex = body.courierCompanyAnnex;
          this.$refs["photobx"].imgRecordData =
            this.editForm.courierAmountAnnex == null
              ? []
              : this.editForm.courierAmountAnnex.map((x, index) => {
                  return {
                    name: "",
                    path: x.annexAddress,
                    seqId: x.seqId, // 常量保证查看的数据不计算在增量文件内
                    sortNumber: index,
                    mychecked: false,
                  };
                });
        }

        if (
          body.courierNumberAnnex !== undefined &&
          null !== body.courierNumberAnnex
        ) {
          this.editForm.courierNumberAnnex = body.courierNumberAnnex;
          this.$refs["photodh"].imgRecordData =
            this.editForm.courierNumberAnnex == null
              ? []
              : this.editForm.courierNumberAnnex.map((x, index) => {
                  return {
                    name: "",
                    path: x.annexAddress,
                    seqId: x.seqId,
                    sortNumber: index,
                    mychecked: false,
                  };
                });
        }
      },
      close() {
        this.editFormVisible = false;
      },
      save() {
        this.$refs["editForm"].validate(async (valid) => {
          if (valid) {
            this.editForm.amount =
              this.editForm.amount === "" ? null : this.editForm.amount;
            this.editForm.courierCompany =
              this.editForm.courierCompany === ""
                ? null
                : this.editForm.courierCompany;
            this.editForm.courierNumber =
              this.editForm.courierNumber === ""
                ? null
                : this.editForm.courierNumber;
            this.editForm.courierAmountAnnex = this.$refs[
              "photobx"
            ].addRecordData;
            this.editForm.delCourierAmountAnnex = this.$refs[
              "photobx"
            ].deleteRecordData;
            this.editForm.courierNumberAnnex = this.$refs[
              "photodh"
            ].addRecordData;
            this.editForm.delCourierNumberAnnex = this.$refs[
              "photodh"
            ].deleteRecordData;
            this.editParam.body = Object.assign(
              this.editParam.body,
              this.editForm
            );
            // 操作记录
            const { header } = this.isAdd
              ? await add(this.editParam)
              : await update(this.editParam);
            if ("1" === header.retCode) {
              this.$emit("refreshData");
              this.$baseMessage(header.retMessage, "success");
              this.editFormVisible = false;
            } else {
              this.$baseMessage(header.retMessage, "error");
            }
            this.saveLoading = false;
          } else {
            return false;
          }
        });
      },
      del() {
        this.$confirm("确认要删除吗")
          .then((_) => {
            delInfo({
              body: {
                seqId: this.object.seqId,
                userNo: this.editParam.body.userNo,
                userRoleNo: this.editParam.body.userRoleNo,
                workOrderNo: this.object.workOrderNo,
              },
              header: {
                currentTime: "2020-09-09 12:12:12",
                requestId: "1",
                sourceType: "site",
              },
            }).then((res) => {
              if (res.header.retCode === "1") {
                this.$baseMessage(res.header.retMessage, "success");
                this.editFormVisible = false;
                this.$emit("refreshData");
              } else {
                this.$message.error(`${res.header.retMessage}`);
              }
              this.saveLoading = false;
            });
          })
          .catch((_) => {});
      },
      eighthBitTime(row, column) {
        return eighthBitTime(row, column);
      },
      /** 当前dialog关闭 */
      handleClose() {
        this.fullFlag = false;
        this.fullIcon = "el-icon-full-screen";
        this.fullTitle = "最大化";
        this.editFormVisible = false;
      },
    },
  };
</script>

<style>
  body {
    font-weight: 700;
    color: #666;
  }
  .edit_title .title {
    margin: 10px 0;
  }
  .el-step__title {
    font-size: 14px;
  }
  .el-step__title.is-process {
    font-weight: bold;
    color: #666;
  }
  html body .el-table th .cell {
    font-weight: 700;
    color: #666;
  }

  .el-form-item__label {
    font-weight: normal;
  }

  .el-date-editor.el-input,
  .el-date-editor.el-input__inner,
  .el-date-editor--daterange.el-input,
  .el-date-editor--daterange.el-input__inner,
  .el-date-editor--timerange.el-input,
  .el-date-editor--timerange.el-input__inner {
    width: 100% !important;
  }

  .el-select,
  .el-cascader {
    width: 100%;
  }
  .my-textarea > textarea {
    height: 32px;
  }
</style>
