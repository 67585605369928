var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          class: _vm.myDialog,
          attrs: {
            title: _vm.title,
            visible: _vm.editFormVisible,
            "close-on-click-modal": false,
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.editFormVisible = $event
            },
            close: _vm.handleClose,
          },
        },
        [
          _c("el-backtop", { attrs: { target: _vm.myDialogPoint } }),
          _c(
            "el-form",
            {
              ref: "editForm",
              staticClass: "table-class demo-form-inline",
              attrs: { model: _vm.editForm, rules: _vm.rules },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 80 } },
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "工单编号", prop: "workOrderNo" } },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: !_vm.isAdd,
                              placeholder: "请输入工单编号",
                            },
                            model: {
                              value: _vm.editForm.workOrderNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.editForm, "workOrderNo", $$v)
                              },
                              expression: "editForm.workOrderNo",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 80 } },
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "报销总金额", prop: "amount" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入报销总金额" },
                            model: {
                              value: _vm.editForm.amount,
                              callback: function ($$v) {
                                _vm.$set(_vm.editForm, "amount", $$v)
                              },
                              expression: "editForm.amount",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 80 } },
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 } },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c("costadd", {
                            ref: "photobx",
                            attrs: {
                              "is-show-file-name": false,
                              type: "50",
                              row: _vm.object,
                              "is-show-down-load": false,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 80 } },
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "快递公司", prop: "courierCompany" },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入快递公司" },
                            model: {
                              value: _vm.editForm.courierCompany,
                              callback: function ($$v) {
                                _vm.$set(_vm.editForm, "courierCompany", $$v)
                              },
                              expression: "editForm.courierCompany",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 80 } },
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "快递单号", prop: "courierNumber" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入快递单号" },
                            model: {
                              value: _vm.editForm.courierNumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.editForm, "courierNumber", $$v)
                              },
                              expression: "editForm.courierNumber",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 80 } },
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 } },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c("costadd", {
                            ref: "photodh",
                            attrs: {
                              "is-show-file-name": false,
                              type: "50",
                              "is-show-down-load": false,
                              row: _vm.object,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              !_vm.isAdd
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "danger", loading: _vm.saveLoading },
                      on: { click: _vm.del },
                    },
                    [_vm._v(" 删 除 ")]
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  attrs: { type: "success", loading: _vm.saveLoading },
                  on: { click: _vm.save },
                },
                [_vm._v(" 保 存 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }