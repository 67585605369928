// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@charset \"UTF-8\"\n/**\n * @copyright CBI cbi@chinacbi.com\n * @description 全局主题变量配置，VIP文档内提供多种好看的配色方案（ant-design风格、layui风格、iview风格），请查看VIP文档主题配置篇\n */\n/* stylelint-disable */\n\n/* 绿荫草场主题、荣耀典藏主题、暗黑之子主题加QQ讨论群972435319、1139183756后私聊群主获取，获取后将主题放到themes文件夹根目录即可 */", ""]);
// Exports
exports.locals = {
	"menu-color": "hsla(0deg, 20%, 98%, 0.95)",
	"menu-color-active": "hsla(0deg, 20%, 98%, 0.95)",
	"menu-background": "#869ac2"
};
module.exports = exports;
