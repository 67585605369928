var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-card",
    { staticClass: "box-card" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 24, md: 12, lg: 24, xl: 24 } },
            [
              _c(
                "el-card",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-search",
                        plain: "",
                        "native-type": "submit",
                      },
                      on: { click: _vm.handleQuery },
                    },
                    [_vm._v(" 查询 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-refresh-left",
                        type: "info",
                        plain: "",
                        "native-type": "submit",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.resetForm("queryForm")
                        },
                      },
                    },
                    [_vm._v(" 重置 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-sort",
                        plain: "",
                        "native-type": "submit",
                      },
                      on: { click: _vm.heightQuery },
                    },
                    [_vm._v(" 高级筛选 ")]
                  ),
                  _c(
                    "el-form",
                    {
                      ref: "queryForm",
                      staticClass: "demo-form-inline my-form-class",
                      staticStyle: { "margin-top": "15px" },
                      attrs: { model: _vm.queryForm },
                    },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { xs: 24, sm: 24, md: 12, lg: 6, xl: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "orderTime" } },
                                [
                                  _c("el-date-picker", {
                                    attrs: {
                                      type: "daterange",
                                      "unlink-panels": "",
                                      "range-separator": "至",
                                      "start-placeholder": "下单开始日期",
                                      "end-placeholder": "下单结束日期",
                                      "picker-options": _vm.pickerOptions,
                                    },
                                    model: {
                                      value: _vm.queryForm.orderTime,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.queryForm,
                                          "orderTime",
                                          $$v
                                        )
                                      },
                                      expression: "queryForm.orderTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { xs: 24, sm: 24, md: 12, lg: 6, xl: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "surveyEntrustDate" } },
                                [
                                  _c("el-date-picker", {
                                    attrs: {
                                      type: "daterange",
                                      "unlink-panels": "",
                                      "range-separator": "至",
                                      "start-placeholder": "调查员委托开始日期",
                                      "end-placeholder": "调查员委托结束日期",
                                      "picker-options": _vm.pickerOptions,
                                    },
                                    model: {
                                      value: _vm.queryForm.surveyEntrustDate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.queryForm,
                                          "surveyEntrustDate",
                                          $$v
                                        )
                                      },
                                      expression: "queryForm.surveyEntrustDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { xs: 24, sm: 24, md: 12, lg: 6, xl: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "surveyVisitDate" } },
                                [
                                  _c("el-date-picker", {
                                    attrs: {
                                      type: "daterange",
                                      "unlink-panels": "",
                                      "range-separator": "至",
                                      "start-placeholder": "调查员走访开始日期",
                                      "end-placeholder": "调查员走访结束日期",
                                      "picker-options": _vm.pickerOptions,
                                    },
                                    model: {
                                      value: _vm.queryForm.surveyVisitDate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.queryForm,
                                          "surveyVisitDate",
                                          $$v
                                        )
                                      },
                                      expression: "queryForm.surveyVisitDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { xs: 24, sm: 24, md: 12, lg: 6, xl: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "surveyCommitDate" } },
                                [
                                  _c("el-date-picker", {
                                    attrs: {
                                      type: "daterange",
                                      "unlink-panels": "",
                                      "range-separator": "至",
                                      "start-placeholder": "调查员提交开始日期",
                                      "end-placeholder": "调查员提交结束日期",
                                      "picker-options": _vm.pickerOptions,
                                    },
                                    model: {
                                      value: _vm.queryForm.surveyCommitDate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.queryForm,
                                          "surveyCommitDate",
                                          $$v
                                        )
                                      },
                                      expression: "queryForm.surveyCommitDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "transition",
                        { attrs: { name: "fade" } },
                        [
                          _c(
                            "el-row",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.isShow,
                                  expression: "isShow",
                                },
                              ],
                              attrs: { transiton: "fade", gutter: 20 },
                            },
                            [
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 24,
                                    md: 12,
                                    lg: 6,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: "auditCommitDate" } },
                                    [
                                      _c("el-date-picker", {
                                        attrs: {
                                          type: "daterange",
                                          "unlink-panels": "",
                                          "range-separator": "至",
                                          "start-placeholder":
                                            "内审提交开始日期",
                                          "end-placeholder": "内审提交结束日期",
                                          "picker-options": _vm.pickerOptions,
                                        },
                                        model: {
                                          value: _vm.queryForm.auditCommitDate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.queryForm,
                                              "auditCommitDate",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "queryForm.auditCommitDate",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 24,
                                    md: 12,
                                    lg: 6,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: "accomplishFlag" } },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            placeholder: "请选择是否完成",
                                            clearable: "",
                                            filterable: "",
                                          },
                                          model: {
                                            value: _vm.queryForm.accomplishFlag,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.queryForm,
                                                "accomplishFlag",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "queryForm.accomplishFlag",
                                          },
                                        },
                                        _vm._l(
                                          _vm.finishOptions,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.value,
                                              attrs: {
                                                label: item.label,
                                                value: item.value,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 24, md: 12, lg: 24, xl: 24 } },
            [
              _c(
                "el-card",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleDownload },
                    },
                    [_vm._v("导出")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleShowDownloadDialog },
                    },
                    [_vm._v(" 全量导出 ")]
                  ),
                  _c(
                    "el-popover",
                    {
                      staticStyle: { "text-align": "center" },
                      attrs: {
                        placement: "right",
                        width: "700",
                        trigger: "click",
                        title: "分析统计数据全量导出",
                      },
                      on: {
                        show: _vm.handleShowTaskPopover,
                        hide: _vm.handleHideTaskPopover,
                      },
                    },
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.downloadTaskLoading,
                              expression: "downloadTaskLoading",
                            },
                          ],
                          attrs: {
                            data: _vm.downloadList,
                            "show-header": false,
                            "empty-text": "暂无下载任务",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              "show-overflow-tooltip": true,
                              "min-width": "250",
                              prop: "fileName",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: { "min-width": "150", prop: "createTime" },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              "show-overflow-tooltip": true,
                              "min-width": "110",
                              align: "center",
                              prop: "taskStatusValue",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: { width: "90", align: "center" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (slot) {
                                  return [
                                    slot.row.type === 3
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              disabled:
                                                slot.row.taskStatus !== 102,
                                              type: "text",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleDownloadZip(
                                                  _vm.ossDomain,
                                                  slot.row.downloadPath
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" 下载 ")]
                                        )
                                      : _vm._e(),
                                    slot.row.taskStatus === 103
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleRetry(
                                                  slot.row.seqId
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" 重试 ")]
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _c("el-pagination", {
                        attrs: {
                          small: "",
                          layout: "prev, pager, next",
                          "current-page":
                            _vm.queryDownloadTaskForm.body.pageNum,
                          "page-size": _vm.queryDownloadTaskForm.body.pageSize,
                          total: _vm.downloadTaskTotal,
                        },
                        on: { "current-change": _vm.handleTaskCurrentChange },
                      }),
                      _c("el-button", {
                        staticClass: "task-list-btn",
                        attrs: {
                          slot: "reference",
                          icon: "el-icon-download",
                          title: "查看全量导出下载任务状态",
                          type: "primary",
                        },
                        slot: "reference",
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading",
                        },
                      ],
                      ref: "tableSort",
                      staticStyle: { "margin-top": "15px" },
                      attrs: {
                        data: _vm.list,
                        "element-loading-text": _vm.elementLoadingText,
                        border: "",
                      },
                      on: { "selection-change": _vm.setSelectRows },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { type: "selection", width: "40" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "工单编号",
                          width: "130",
                          prop: "workOrderNo",
                          align: "center",
                          fixed: "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "targetSubject",
                          label: "目标主体",
                          width: "150",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { label: "省", prop: "state" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " + _vm._s(scope.row.state || "-") + " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "市", prop: "city" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " + _vm._s(scope.row.city || "-") + " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "区/县/地级市", prop: "region" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " + _vm._s(scope.row.region || "-") + " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "下单日期_日", prop: "orderDate" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " + _vm._s(scope.row.orderDate || "-") + " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "调查员委托日期",
                          prop: "surveyVisitDate",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.surveyVisitDate || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "调查员走访日期",
                          prop: "surveyEntrustDate",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.surveyEntrustDate || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "调查员提交日期",
                          prop: "surveyCommitDate",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.surveyCommitDate || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "内审提交日期",
                          prop: "auditCommitDate",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.auditCommitDate || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "Project Name", prop: "projectNameNo" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.projectNameNo || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "工单是否完成",
                          prop: "accomplishFlagValue",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      scope.row.accomplishFlagValue || "-"
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "PL", prop: "plUserValue" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.plUserValue || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "预约", prop: "bookUserValue" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.bookUserValue || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "督导", prop: "monitorUserValue" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.monitorUserValue || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "调查", prop: "surveyUserValue" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.surveyUserValue || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "调查员类型", prop: "surveyUserType" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.surveyUserType || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "内审", prop: "auditUserValue" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.auditUserValue || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "用户", prop: "pchUserValue" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.pchUserValue || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "放弃类型", prop: "abandonTypeValue" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.abandonTypeValue || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "HK退回", prop: "hkFallbackValue" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.hkFallbackValue || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "工单状态",
                          prop: "workOrderStatusValue",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      scope.row.workOrderStatusValue || "-"
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "环节/整体滞留时间",
                          prop: "stepAndStranded",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.stepAndStranded || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "项目来源", prop: "sourceValue" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.sourceValue || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "项目名称", prop: "projectName" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.projectName || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "项目类型", prop: "projectType" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.projectType || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "到期时间", prop: "maturityDate" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.maturityDate || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "委托方", prop: "clientFullName" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.clientFullName || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "对账ID", prop: "reconciliationId" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.reconciliationId || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "SV Code", prop: "svCode" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " + _vm._s(scope.row.svCode || "-") + " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "报告期限",
                          prop: "reportTimeLimitValue",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      scope.row.reportTimeLimitValue || "-"
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "走访收费", prop: "visitFee" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " + _vm._s(scope.row.visitFee || "-") + " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "加急走访收费",
                          prop: "urgentVisitFee",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.urgentVisitFee || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "再次走访收费", prop: "againVisitFee" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.againVisitFee || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "是否扣罚",
                          prop: "deductionFlagValue",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      scope.row.deductionFlagValue || "-"
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "是否质疑", prop: "questionFlagValue" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.questionFlagValue || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "督导总绩效(元)", prop: "supervision" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.supervision || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "调查总绩效(元)",
                          prop: "investigation",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.investigation || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "内审总绩效(元)",
                          prop: "internalAudit",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.internalAudit || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "成本总金额(元)", prop: "costAmount" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.costAmount || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "报销总金额(元)", prop: "amount" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " + _vm._s(scope.row.amount || "-") + " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "快递公司", prop: "courierCompany" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.courierCompany || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "快递单号", prop: "courierNumber" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.courierNumber || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          width: "100px",
                          fixed: "right",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content: "详情",
                                      placement: "top-start",
                                    },
                                  },
                                  [
                                    _c("el-button", {
                                      attrs: {
                                        size: "mini",
                                        type: "primary",
                                        icon: "el-icon-document",
                                        circle: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.goDetailPage(scope.row)
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 24, md: 12, lg: 24, xl: 24 } },
            [
              _c(
                "el-card",
                [
                  _c("el-pagination", {
                    attrs: {
                      background: _vm.background,
                      "current-page": _vm.queryForm.pageNum,
                      layout: _vm.layout,
                      "page-size": _vm.queryForm.pageSize,
                      total: _vm.total,
                    },
                    on: {
                      "current-change": _vm.handleCurrentChange,
                      "size-change": _vm.handleSizeChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "downloadDialog",
          attrs: {
            title: "下载确认",
            visible: _vm.downloadVisible,
            width: "30%",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.downloadVisible = $event
            },
          },
        },
        [
          _c("span", [_vm._v("是否要下载所有分析统计的数据？")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.downloadVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.downloadLoading, type: "primary" },
                  on: { click: _vm.downloadAll },
                },
                [_vm._v(" 确 定 ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }