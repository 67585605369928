var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "login-container" },
    [
      _vm.nodeEnv !== "development"
        ? _c("el-alert", {
            staticStyle: { position: "fixed" },
            attrs: {
              title: "欢迎登录实地平台！",
              type: "success",
              closable: false,
            },
          })
        : _vm._e(),
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { xs: 24, sm: 24, md: 12, lg: 16, xl: 16 } }, [
            _c("div", { staticStyle: { color: "transparent" } }, [
              _vm._v("占位符"),
            ]),
          ]),
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
            [
              _c(
                "el-form",
                {
                  ref: "loginForm",
                  staticClass: "login-form",
                  attrs: {
                    model: _vm.loginForm,
                    rules: _vm.loginFormRules,
                    "label-position": "left",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 } },
                        [
                          _c("div", { staticClass: "title" }, [
                            _vm._v("您好！"),
                          ]),
                        ]
                      ),
                      _c(
                        "el-col",
                        {
                          attrs: {
                            xs: 12,
                            sm: 12,
                            md: 12,
                            lg: 12,
                            xl: 12,
                            align: "right",
                          },
                        },
                        [
                          _c("div", [
                            _c("img", {
                              staticStyle: { width: "96px", height: "56px" },
                              attrs: { src: _vm.logoUrl },
                            }),
                          ]),
                        ]
                      ),
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 } },
                        [
                          _c("div", { staticClass: "title-tips" }, [
                            _vm._v("欢迎来到" + _vm._s(_vm.title) + "！"),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-top": "20px" },
                      attrs: { prop: "username" },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "svg-container svg-container-admin" },
                        [_c("vab-icon", { attrs: { icon: ["fas", "user"] } })],
                        1
                      ),
                      _c("el-input", {
                        directives: [{ name: "focus", rawName: "v-focus" }],
                        attrs: {
                          placeholder: "请输入用户名",
                          tabindex: "1",
                          type: "text",
                        },
                        model: {
                          value: _vm.loginForm.username,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.loginForm,
                              "username",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "loginForm.username",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "password" } },
                    [
                      _c(
                        "span",
                        { staticClass: "svg-container" },
                        [_c("vab-icon", { attrs: { icon: ["fas", "lock"] } })],
                        1
                      ),
                      _c("el-input", {
                        key: _vm.passwordType,
                        ref: "password",
                        attrs: {
                          type: _vm.passwordType,
                          tabindex: "2",
                          placeholder: "请输入密码",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.handleLogin.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.loginForm.password,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.loginForm,
                              "password",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "loginForm.password",
                        },
                      }),
                      _vm.passwordType === "password"
                        ? _c(
                            "span",
                            {
                              staticClass: "show-password",
                              on: { click: _vm.handlePassword },
                            },
                            [
                              _c("vab-icon", {
                                attrs: { icon: ["fas", "eye-slash"] },
                              }),
                            ],
                            1
                          )
                        : _c(
                            "span",
                            {
                              staticClass: "show-password",
                              on: { click: _vm.handlePassword },
                            },
                            [
                              _c("vab-icon", {
                                attrs: { icon: ["fas", "eye"] },
                              }),
                            ],
                            1
                          ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "span",
                        {
                          staticClass: "svg-container",
                          staticStyle: { top: "15px" },
                        },
                        [
                          _c("vab-icon", {
                            attrs: { icon: ["fas", "shield-alt"] },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "login-btn",
                          staticStyle: {
                            width: "100%",
                            height: "60px",
                            "text-align": "left",
                            "padding-left": "40px",
                          },
                          attrs: { type: _vm.isSuccess },
                          on: { click: _vm.showVal },
                        },
                        [_vm._v(" " + _vm._s(_vm.showText) + " ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "login-btn",
                      attrs: { loading: _vm.loading, type: "primary" },
                      on: { click: _vm.handleLogin },
                    },
                    [_vm._v(" 登录 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "myDialog",
          attrs: {
            title: "图片转正完成校验",
            visible: _vm.showValidate,
            width: "350px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showValidate = $event
            },
            close: _vm.close,
          },
        },
        [
          _c("el-button", {
            staticStyle: { position: "absolute", right: "10px" },
            attrs: { icon: "el-icon-refresh", circle: "", type: "warning" },
            on: { click: _vm.refresh },
          }),
          _c("drag-verify-img-rotate", {
            ref: "dragVerify",
            attrs: {
              imgsrc: _vm.validateImage,
              "is-passing": _vm.isPassing,
              "show-tips": true,
              text: "请按住滑块拖动",
              "success-text": "验证通过",
              "fail-tip": "验证失败，图片旋转正即可通过校验",
              "diff-degree": "90",
              "handler-icon": "el-icon-d-arrow-right",
              "success-icon": "el-icon-circle-check",
              width: "300",
            },
            on: {
              "update:isPassing": function ($event) {
                _vm.isPassing = $event
              },
              "update:is-passing": function ($event) {
                _vm.isPassing = $event
              },
              passcallback: _vm.passCallBack,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }