<template>
  <div class="login-container">
    <el-alert
      v-if="nodeEnv !== 'development'"
      title="欢迎登录实地平台！"
      type="success"
      :closable="false"
      style="position: fixed"
    ></el-alert>
    <el-row>
      <el-col :xs="24" :sm="24" :md="12" :lg="16" :xl="16">
        <div style="color: transparent">占位符</div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
        <el-form
          ref="loginForm"
          :model="loginForm"
          :rules="loginFormRules"
          class="login-form"
          label-position="left"
        >
          <el-row>
            <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="title">您好！</div>
            </el-col>
            <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12" align="right">
              <div>
                <img :src="logoUrl" style="width: 96px; height: 56px" />
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <div class="title-tips">欢迎来到{{ title }}！</div>
            </el-col>
          </el-row>
          <el-form-item style="margin-top: 20px" prop="username">
            <span class="svg-container svg-container-admin">
              <vab-icon :icon="['fas', 'user']" />
            </span>
            <el-input
              v-model.trim="loginForm.username"
              v-focus
              placeholder="请输入用户名"
              tabindex="1"
              type="text"
            />
          </el-form-item>
          <el-form-item prop="password">
            <span class="svg-container">
              <vab-icon :icon="['fas', 'lock']" />
            </span>
            <el-input
              :key="passwordType"
              ref="password"
              v-model.trim="loginForm.password"
              :type="passwordType"
              tabindex="2"
              placeholder="请输入密码"
              @keyup.enter.native="handleLogin"
            />
            <span
              v-if="passwordType === 'password'"
              class="show-password"
              @click="handlePassword"
            >
              <vab-icon :icon="['fas', 'eye-slash']"></vab-icon>
            </span>
            <span v-else class="show-password" @click="handlePassword">
              <vab-icon :icon="['fas', 'eye']"></vab-icon>
            </span>
          </el-form-item>
          <el-form-item>
            <span class="svg-container" style="top: 15px">
              <vab-icon :icon="['fas', 'shield-alt']"></vab-icon>
            </span>
            <el-button
              class="login-btn"
              style="
                width: 100%;
                height: 60px;
                text-align: left;
                padding-left: 40px;
              "
              :type="isSuccess"
              @click="showVal"
            >
              {{ showText }}
            </el-button>
          </el-form-item>

          <el-button
            :loading="loading"
            class="login-btn"
            type="primary"
            @click="handleLogin"
          >
            登录
          </el-button>
          <!-- <router-link to="/register">
            <div style="margin-top: 20px;">注册</div>
          </router-link> -->
        </el-form>
      </el-col>
    </el-row>
    <el-dialog
      title="图片转正完成校验"
      class="myDialog"
      :visible.sync="showValidate"
      width="350px"
      @close="close"
    >
      <el-button
        icon="el-icon-refresh"
        circle
        type="warning"
        style="position: absolute; right: 10px"
        @click="refresh"
      ></el-button>
      <drag-verify-img-rotate
        ref="dragVerify"
        :imgsrc="validateImage"
        :is-passing.sync="isPassing"
        :show-tips="true"
        text="请按住滑块拖动"
        success-text="验证通过"
        fail-tip="验证失败，图片旋转正即可通过校验"
        diff-degree="90"
        handler-icon="el-icon-d-arrow-right"
        success-icon="el-icon-circle-check"
        width="300"
        @passcallback="passCallBack"
      ></drag-verify-img-rotate>
    </el-dialog>
  </div>
</template>

<script>
  import { isPassword } from "@/utils/validate";
  import dragVerifyImgRotate from "vue-drag-verify-img-rotate";
  import { random } from "@/utils";

  export default {
    name: "Login",
    components: {
      dragVerifyImgRotate,
    },
    directives: {
      focus: {
        inserted(el) {
          el.querySelector("input").focus();
        },
      },
    },
    data() {
      const validateusername = (rule, value, callback) => {
        if ("" === value) {
          callback(new Error("用户名不能为空"));
        } else {
          callback();
        }
      };
      const validatePassword = (rule, value, callback) => {
        if (!isPassword(value)) {
          callback(new Error("密码不能少于6位"));
        } else {
          callback();
        }
      };
      return {
        logoUrl: require("../../assets/logo.svg"),
        nodeEnv: process.env.NODE_ENV,
        title: this.$baseTitle,
        loginForm: {
          username: "",
          password: "",
        },
        loginFormRules: {
          username: [
            {
              required: true,
              trigger: "blur",
              validator: validateusername,
            },
          ],
          password: [
            {
              required: true,
              trigger: "blur",
              validator: validatePassword,
            },
          ],
        },
        loading: false,
        passwordType: "password",
        redirect: undefined,
        showValidate: false,
        isPassing: false,
        isSuccess: "default",
        isValidate: false,
        showText: "请点击按钮进行验证",
        validateImage: "/static/img/val1.jpg",
      };
    },
    watch: {
      $route: {
        handler(route) {
          this.redirect = (route.query && route.query.redirect) || "/";
        },
        immediate: true,
      },
    },
    created() {
      // this.getCookie();
    },
    mounted() {
      if ("production" !== process.env.NODE_ENV) {
        this.loginForm.username = "";
        this.loginForm.password = "";
      }
    },
    methods: {
      /** 从cookie中获取用户信息 */
      // getCookie() {
      //   const username = Cookies.get("username");
      //   const password = Cookies.get("password");
      //   const rememberMe = Cookies.get("rememberMe");
      // }

      handlePassword() {
        this.passwordType === "password"
          ? (this.passwordType = "")
          : (this.passwordType = "password");
        this.$nextTick(() => {
          this.$refs.password.focus();
        });
      },
      handleLogin() {
        if (this.isValidate) {
          this.$refs.loginForm.validate(async (valid) => {
            if (valid) {
              sessionStorage.setItem("password", this.loginForm.password);
              this.loading = true;
              await this.$store
                .dispatch("user/login", this.loginForm)
                .then(() => {
                  const routerPath =
                    this.redirect === "/404" || this.redirect === "/401"
                      ? "/"
                      : this.redirect;
                  this.$router.push(routerPath).catch(() => {});
                })
                .catch(() => {
                  this.loading = false;
                });
            } else {
              return false;
            }
          });
        } else {
          this.isSuccess = "warning";
        }

        // setTimeout(() => {
        //   window.open("https://github.com/CBI/cbi-site-front");
        // }, 30000);
      },
      getRandomOneToFive() {
        return random(1, 10);
      },
      showVal() {
        this.isValidate = false;
        if (this.showText !== "验证通过") {
          this.showValidate = !this.showValidate;
          let r = this.getRandomOneToFive();
          this.validateImage = "/static/img/val" + r + ".jpg";
          this.refresh();
        }
      },
      passCallBack() {
        setTimeout(() => {
          this.showValidate = false;
        }, 1000);
        this.isValidate = true;
        this.isSuccess = "success";
        this.showText = "验证通过，请点击登录";
      },
      refresh() {
        this.isPassing = false;
        this.isSuccess = "default";
        this.showText = "请点击按钮进行验证";
        let r = this.getRandomOneToFive();
        this.validateImage = "/static/img/val" + r + ".jpg";
      },
    },
  };
</script>

<style lang="scss" scoped>
  .login-container {
    height: 100vh;
    //background: url("~@/assets/login_images/background1.jpg") center center
    //  fixed no-repeat;
    background: url("~@/assets/login_images/background7.jpg") center center
      fixed no-repeat;
    background-size: cover;
    /*background-image: radial-gradient(
        circle at 20% 14%,
        rgba(27, 27, 27, 0.05) 0%,
        rgba(27, 27, 27, 0.05) 50%,
        rgba(126, 126, 126, 0.05) 50%,
        rgba(126, 126, 126, 0.05) 100%
      ),
      radial-gradient(
        circle at 18% 51%,
        rgba(248, 248, 248, 0.05) 0%,
        rgba(248, 248, 248, 0.05) 50%,
        rgba(26, 26, 26, 0.05) 50%,
        rgba(26, 26, 26, 0.05) 100%
      ),
      radial-gradient(
        circle at 29% 38%,
        rgba(160, 160, 160, 0.05) 0%,
        rgba(160, 160, 160, 0.05) 50%,
        rgba(212, 212, 212, 0.05) 50%,
        rgba(212, 212, 212, 0.05) 100%
      ),
      linear-gradient(90deg, rgb(35, 74, 255), rgb(132, 161, 173));*/

    .title {
      font-size: 48px;
      font-weight: 500;
      color: rgb(250, 250, 250);
    }

    .title-tips {
      font-size: 26px;
      font-weight: 400;
      color: rgb(247, 248, 252);
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .login-btn {
      display: inherit;
      width: 220px;
      height: 60px;

      border: 0;

      &:hover {
        opacity: 0.9;
      }
    }

    .login-form {
      position: relative;
      max-width: 100%;
      margin: calc((90vh - 425px) / 2) 10% 10%;
      overflow: hidden;

      padding: 20px;
      background: #303e4f;
      opacity: 0.8;
      color: #fff;

      .forget-password {
        width: 100%;
        text-align: left;

        .forget-pass {
          width: 129px;
          height: 19px;
          font-size: 20px;
          font-weight: 400;
          color: rgba(92, 102, 240, 1);
        }
      }
    }

    .tips {
      margin-bottom: 10px;
      font-size: $base-font-size-default;
      color: $base-color-white;

      span {
        &:first-of-type {
          margin-right: 16px;
        }
      }
    }

    .title-container {
      position: relative;

      .title {
        margin: 0 auto 40px auto;
        font-size: 34px;
        font-weight: bold;
        color: $base-color-blue;
        text-align: center;
      }
    }

    .svg-container {
      position: absolute;
      top: 14px;
      left: 15px;
      z-index: $base-z-index;
      font-size: 16px;
      color: #d7dee3;
      cursor: pointer;
      user-select: none;
    }

    .show-password {
      position: absolute;
      top: 14px;
      right: 25px;
      font-size: 16px;
      color: #d7dee3;
      cursor: pointer;
      user-select: none;
    }

    ::v-deep {
      .el-form-item {
        padding-right: 0;
        margin: 20px 0;
        color: #454545;
        background: transparent;
        border: 1px solid transparent;
        border-radius: 2px;

        &__content {
          min-height: $base-input-height;
          line-height: $base-input-height;
        }

        &__error {
          position: absolute;
          top: 100%;
          left: 18px;
          font-size: $base-font-size-small;
          line-height: 18px;
          color: $base-color-red;
        }
      }

      .el-input {
        box-sizing: border-box;

        input {
          height: 58px;
          padding-left: 45px;
          font-size: $base-font-size-default;
          line-height: 58px;
          color: $base-font-color;
          background: #f6f4fc;
          border: 0;
          caret-color: $base-font-color;
        }
      }
    }
    .el-button--primary {
      color: #fff !important;
      background-color: #0060e4;
      border-color: #6f81a4;
    }

    .el-button--primary:hover,
    .el-button--primary:focus {
      background: #0060e4;
      border-color: #8c9ab6;
      color: #fff;
    }
  }
</style>
<style scoped>
  .myDialog >>> .el-dialog {
    margin-top: 23vh !important;
  }
  .myDialog >>> .el-dialog__header {
    padding: 10px !important;
  }
  .myDialog >>> .el-dialog__body {
    padding: 10px !important;
  }
  .myDialog >>> .drag-verify-container > div {
    margin: 0 auto;
  }
  .myDialog >>> .progressBarBg {
    background: #c62c81;
  }
  .myDialog >>> .completedBg {
    background: #c62c9a;
  }

  .el-alert--success.is-light {
    background-color: #deebf7;
    color: #0070c0;
  }
</style>
