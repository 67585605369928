import { returnBitTimestamp, deepClone } from "@/utils/index";

/**
 * @copyright CBI cbi@chinacbi.com
 * @param length
 * @returns {string}
 * @description
 * <br>2020/8/25 16:06 parker 创建实地请求头
 */
export function createSiteHeader() {
  let header = {
    currentTime: "",
    requestId: "1",
    sourceType: "site",
  };
  header.currentTime = returnBitTimestamp(new Date());
  return header;
}

/**
 * @copyright CBI cbi@chinacbi.com
 * @param length
 * @returns {string}
 * @description
 * <br>2020/8/25 16:06 parker 创建实地请求数据
 */
export function createSiteData(body) {
  let data = {};
  let header = createSiteHeader();
  data["header"] = header;
  data["body"] = deepClone(body);
  return data;
}
