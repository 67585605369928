<template>
  <div>
    <el-form
      ref="editForm"
      class="table-class demo-form-inline"
      :model="editForm"
      :rules="rules"
    >
      <el-row :gutter="80">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <el-form-item label="成本总金额" prop="costAmount">
            <el-input
              v-model="editForm.costAmount"
              :disabled="true"
              placeholder=""
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="80">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <el-form-item>
            <costadd
              v-show="isShowAttachment"
              ref="photo"
              :is-show-file-name="false"
              :is-show-down-load="false"
              :type="'50'"
              :row="object"
              :is-edit="false"
            ></costadd>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  // 拍摄照片
  import costadd from "@/views/workplace/components/costAdd";
  import {
    add,
    update,
    queryCostInfo,
  } from "@/api/workplace/supervison/costAdd";
  import { getRoleKey } from "@/utils/index";
  import { isNum } from "@/utils/validate";

  export default {
    name: "Edit",
    components: {
      costadd,
    },
    data() {
      const validateNum = (rule, value, callback) => {
        if (!isNum(value)) {
          callback(new Error("请输入数字，允许带两位小数"));
        } else {
          callback();
        }
      };
      return {
        isShowAttachment: true,
        isAdd: true,
        saveLoading: false,
        annexGroup: [],
        title: "",
        editFormVisible: false,
        object: {},
        //编辑给后端的接口参数
        editForm: {
          attachmentList: [],
          costAmount: null,
        },
        // 获取工单详情
        editParam: {
          body: {
            userNo: "",
            userRoleNo: "",
            workOrderNo: null,
          },
          header: {
            currentTime: "2020-09-09 12:12:12",
            requestId: "1",
            sourceType: "site",
          },
        },
        rules: {
          costAmount: [
            {
              required: true,
              trigger: "blur",
              message: "请输入成本总金额！",
            },
            { validator: validateNum, trigger: "blur" },
          ],
        },
      };
    },
    computed: {
      ...mapGetters({
        userNo: "user/userNo",
        roles: "user/roles",
        endpoinxBak: "user/endpoinxBak",
        OSSClient: "user/OSSClient",
      }),
    },
    created() {
      this.editParam.body.userNo = this.userNo;
      this.editParam.body.userRoleNo = getRoleKey("supervisor", this.roles);
    },
    mounted() {},
    methods: {
      async showEdit(row, isAdd) {
        this.isAdd = isAdd;
        this.title = isAdd ? "成本录入" : "成本编辑";
        this.row = Object.assign(row, {
          workplace: "book_person",
          flag: "edit",
        });
        this.object = row;
        this.editForm.workOrderNo =
          row.workOrderNo === undefined ? "" : row.workOrderNo;
        if (!this.isAdd) {
          const { body, header } = await queryCostInfo({
            workOrderNo: this.object.workOrderNo,
          });
          this.editFormVisible = true;
          this.$nextTick(() => {
            this.$refs["editForm"].resetFields();
            this.$refs["photo"].checkAll = false;
            this.$refs["photo"].imgRecordData = [];
            this.bindData(body);
          });
        } else {
          this.editFormVisible = true;
          this.$nextTick(() => {
            this.$refs["editForm"].resetFields();
            this.$refs["photo"].checkAll = false;
            this.$refs["photo"].imgRecordData = [];
          });
        }
      },
      bindData(body) {
        this.editForm.costAmount = body.costAmount;
        if (body.attachmentList !== undefined && null !== body.attachmentList) {
          this.editForm.attachmentList = body.attachmentList;
          this.object.attachmentList = this.editForm.attachmentList;
          this.$refs["photo"].imgRecordData = this.editForm.attachmentList.map(
            (x, index) => {
              return {
                name: x.name,
                path: x.path,
                seqId: x.seqId,
                sortNumber: index,
                mychecked: false,
              };
            }
          );
        } else {
          this.isShowAttachment = false;
        }
      },
      close() {
        this.editFormVisible = false;
      },
      save() {
        this.$refs["editForm"].validate(async (valid) => {
          if (valid) {
            this.saveLoading = true;
            if (
              null != this.$refs["photo"].imgRecordData &&
              this.$refs["photo"].imgRecordData.length > 0
            ) {
              this.editForm.attachmentList = this.$refs[
                "photo"
              ].imgRecordData.map((x) => {
                return { name: x.name, path: x.path, seqId: x.seqId };
              });
            }
            this.editParam.body = Object.assign(
              this.editParam.body,
              this.editForm
            );
            // 操作记录
            const { header } = this.isAdd
              ? await add(this.editParam)
              : await update(this.editParam);
            if ("1" === header.retCode) {
              this.$emit("refreshData");
              this.$baseMessage(header.retMessage, "success");
              this.editFormVisible = false;
            } else {
              this.$baseMessage(header.retMessage, "error");
            }
            this.saveLoading = false;
          } else {
            return false;
          }
        });
      },
    },
  };
</script>

<style>
  body {
    font-weight: 700;
    color: #666;
  }
  .edit_title .title {
    margin: 10px 0;
  }
  .el-step__title {
    font-size: 14px;
  }
  .el-step__title.is-process {
    font-weight: bold;
    color: #666;
  }
  html body .el-table th .cell {
    font-weight: 700;
    color: #666;
  }

  .el-form-item__label {
    font-weight: normal;
  }

  .el-date-editor.el-input,
  .el-date-editor.el-input__inner,
  .el-date-editor--daterange.el-input,
  .el-date-editor--daterange.el-input__inner,
  .el-date-editor--timerange.el-input,
  .el-date-editor--timerange.el-input__inner {
    width: 100% !important;
  }

  .el-select,
  .el-cascader {
    width: 100%;
  }
  .my-textarea > textarea {
    height: 32px;
  }
</style>
