<template>
  <div style="background: #f6f8f9">
    <div style="margin-bottom: 10px">{{ companyName }}</div>
    <el-tabs
      v-model="activeName"
      type="border-card"
      :tab-position="tabPosition"
      @tab-click="handleClick"
    >
      <el-tab-pane label="取证资料" name="evidence">
        <evidence
          v-if="object.projectSign == null"
          ref="evidence"
          :row="object"
        ></evidence>
        <evidence-history
          v-else
          ref="evidenceHistory"
          :row="object"
        ></evidence-history>
      </el-tab-pane>
      <el-tab-pane label="拍摄照片" name="photo">
        <photo ref="photo" :type="'50'" :row="object"></photo>
      </el-tab-pane>
      <el-tab-pane label="拍摄录像" name="screenVideo">
        <screenVideo ref="screenVideo" :type="'51'" :row="object"></screenVideo>
      </el-tab-pane>
      <el-tab-pane label="扫描文件" name="scanFile">
        <scanFile ref="scanFile" :type="'52'" :row="object"></scanFile>
      </el-tab-pane>
      <el-tab-pane label="录制音频" name="screenAudio">
        <screenAudio ref="screenAudio" :type="'53'" :row="object"></screenAudio>
      </el-tab-pane>
      <el-tab-pane label="会议信息" name="meeting">
        <meeting ref="meeting" :type="'57'" :row="object"></meeting>
      </el-tab-pane>
      <el-tab-pane label="标注记录" name="labelRecord">
        <labelRecord ref="labelRecord" :type="'56'" :row="object"></labelRecord>
      </el-tab-pane>
      <el-tab-pane label="企业信息" name="company">
        <company ref="company" :row="object"></company>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
  import company from "@/views/workplace/components/company";
  import meeting from "@/views/workplace/components/meeting";
  // 取证资料
  import evidence from "@/views/workplace/components/evidence";
  import evidenceHistory from "@/views/workplace/components/evidenceHistory";
  // 拍摄照片
  import photo from "@/views/workplace/components/photo";
  // 拍摄录像
  import screenVideo from "@/views/workplace/components/screenVideo";
  // 扫描文件
  import scanFile from "@/views/workplace/components/scanFile";
  // 录制音频
  import screenAudio from "@/views/workplace/components/screenAudio";
  import {
    getOperationInfo,
    getOrderDetail,
    searchAttach,
  } from "@/api/workplace/tools";
  import labelRecord from "@/views/workplace/components/labelRecord";
  import {
    eighthBitTimestamp,
    returnBitTimestamp,
    returnMinutetamp,
    downloadFile,
    isExistOSS,
  } from "@/utils";
  import { mapGetters } from "vuex";
  export default {
    name: "Detail",
    components: {
      evidence,
      evidenceHistory,
      photo,
      screenVideo,
      scanFile,
      screenAudio,
      meeting,
      labelRecord,
      company,
    },
    data() {
      return {
        // 当前激活tab
        activeName: "evidence",
        annexGroup: [],
        tabPosition: "top",
        orderCreateTime: "2020-07-19 15:43",
        tableData: [],

        rules: {
          remark: [
            { required: true, message: "请输入小记", trigger: "blur" },
            {
              max: 500,
              message: "小记不能超过500个汉字",
              trigger: "blur",
            },
          ],
        },
        orderForm: {
          remark: "",
        },
        param: {
          body: {
            userNo: "",
            userRoleNo: "",
            workOrderNo: null,
            pageSize: 10,
            pageNum: 1,
          },
          header: {
            currentTime: "",
            requestId: "1",
            sourceType: "site",
          },
        },
        operationInfo: [],
        // 给子组件传值
        object: {
          userNo: null,
          userRoleNo: null,
          workOrderNo: null,
          seqId: null,
        },
        companyName: null,
      };
    },
    computed: {
      ...mapGetters({
        OSSClient: "user/OSSClient",
        endpoinxBak: "user/endpoinxBak",
      }),
    },
    created() {
      this.param.body.workOrderNo = this.$route.query.workOrderNo;
      this.param.body.userNo = this.$route.query.userNo;
      this.param.body.userRoleNo = this.$route.query.userRoleNo;
      this.showDetail();
      /** 查询附件信息 */
      this.attach();
    },
    methods: {
      // 时分下单日期格式处理
      Minutetamp(row, column) {
        var date = row[column.property];
        if (date === undefined) {
          return "-";
        }
        return returnMinutetamp(Date.parse(date));
      },
      // 时分下单日期格式处理
      eighthBitTime(row, column) {
        var date = row[column.property];
        if (date === undefined) {
          return "-";
        }
        return eighthBitTimestamp(Date.parse(date));
      },
      /** 查询附件信息 */
      async attach() {
        this.param.body.workOrderNo = this.$route.query.workOrderNo;
        this.param.header.currentTime = returnBitTimestamp(new Date());
        const { body, header } = await searchAttach(this.param);
        if (header.retCode === "1") {
          this.annexGroup = body;
        } else {
          this.$baseMessage(header.retMessage, "error");
        }
      },
      async showDetail() {
        this.tableData = [];
        this.orderNo = this.$route.query.workOrderNo;
        this.object.userNo = this.$route.query.userNo;
        this.object.projectId = this.$route.query.projectId;
        this.object.userRoleNo = this.$route.query.userRoleNo;
        this.object.workOrderNo = this.$route.query.workOrderNo;
        this.object.seqId = this.$route.query.seqId;
        this.object.targetSubject = this.$route.query.target;
        this.object.endpoinxBak = this.endpoinxBak;
        this.object.OSSClient = this.OSSClient;
        this.object.projectSign = this.$route.query.projectSign;
        /** 给子组件的传参 */
        this.object = Object.assign(this.object, {
          workplace: "customer",
          flag: "edit",
        });

        // 获取工单详情
        this.param.body.workOrderNo = this.$route.query.workOrderNo;
        this.tableData.push(
          Object.assign({}, (await getOrderDetail(this.param)).body)
        );
        this.companyName = this.tableData[0].targetSubject;
        // 获取操作工单日志信息
        this.param.body.workOrderNo = this.$route.query.workOrderNo;
        this.operationInfo = (await getOperationInfo(this.param)).body;
        if (this.object.projectSign !== null) {
          this.$refs["evidenceHistory"].reset();
          this.$refs["evidenceHistory"].fetchData();
        } else {
          this.$refs["evidence"].reset();
          this.$refs["evidence"].fetchData();
        }
      },
      handleClick(tab, event) {
        if (tab.name === "evidence") {
          if (this.object.projectSign !== null) {
            this.$refs["evidenceHistory"].reset();
            this.$refs["evidenceHistory"].fetchData();
          } else {
            this.$refs["evidence"].reset();
            this.$refs["evidence"].fetchData();
          }
        }
        if (tab.name === "photo") {
          this.$refs["photo"].fetchData();
        }
        if (tab.name === "screenVideo") {
          this.$refs["screenVideo"].fetchData();
        }
        if (tab.name === "scanFile") {
          this.$refs["scanFile"].fetchData();
        }
        if (tab.name === "screenAudio") {
          this.$refs["screenAudio"].fetchData();
        }
        if (tab.name === "telRecord") {
          this.$refs["telRecord"].fetchData();
        }
        if (tab.name === "clockIn") {
          this.$refs["clockIn"].fetchData();
        }
        if (tab.name === "meeting") {
          this.$refs["meeting"].fetchData();
        }
        if (tab.name === "remark") {
          this.$refs["remark"].fetchData();
        }
        if (tab.name === "back") {
          this.$refs["back"].fetchData();
        }
        if (tab.name === "labelRecord") {
          this.$refs["labelRecord"].fetchData();
        }
        if (tab.name === "company") {
          this.$refs["company"].init();
        }
      },
      /** 回退上级页面 */
      handleReturn() {
        if (this.$route.name === "OrderSecondReview/detail") {
          this.$router.push({
            path: "/workplace/user/orderSecondReview",
          });
        } else {
          this.$router.push({
            path: "/workplace/user/summary",
          });
        }
      },
      /** 附件下载 */
      handleDownload(item) {
        isExistOSS(this.OSSClient, item.path)
          .then(() => {
            downloadFile(item);
          })
          .catch(() => {
            this.$message.error("当前文件不存在！");
          });
      },
    },
  };
</script>

<style>
  body {
    font-weight: 700;
    color: #666;
  }
  .edit_title .title {
    margin: 10px 0;
  }
  .el-step__title {
    font-size: 14px;
  }
  .el-step__title.is-process {
    font-weight: bold;
    color: #666;
  }
  html body .el-table th .cell {
    font-weight: 700;
    color: #666;
  }

  .el-form-item__label {
    font-weight: normal;
  }

  .el-date-editor.el-input,
  .el-date-editor.el-input__inner,
  .el-date-editor--daterange.el-input,
  .el-date-editor--daterange.el-input__inner,
  .el-date-editor--timerange.el-input,
  .el-date-editor--timerange.el-input__inner {
    width: 100% !important;
  }

  .el-select,
  .el-cascader {
    width: 100%;
  }

  .myDiv {
    width: 150px;
    height: 150px;
    text-align: center;
  }

  .myDiv img {
    width: 130px;
    height: 130px;
    text-align: center;
  }
</style>
